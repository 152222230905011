import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Card, Badge, Flex, Metric, Title, Icon, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { CurrencyDollarIcon, TrophyIcon, CursorArrowRaysIcon, BanknotesIcon, CheckIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import LoadingView from "../utils/LoadingView";
import Toast, { ToastState } from "../utils/DesignSystem/Toast";
import * as networkManager from "../networking/NetworkManager";
import { PUBReferralDetail, PUBBReferralOverviewStatsResponse } from "../models/Models";
import { MonthPicker } from "../utils/MonthPicker/MonthPicker.jsx";
import { MonthInput } from "../utils/MonthPicker/MonthInput.jsx";
import * as analyticsManager from "../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";
import Tooltip from "../utils/Tooltip";
import { Spinner } from "react-bootstrap";

const EarnDashboardView = (props) => {
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});
	const [referralDetail, setReferralDetail] = useState<PUBReferralDetail | null>(null);
	const [overviewStatResponse, setOverviewStatResponse] = useState<PUBBReferralOverviewStatsResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMetricLoading, setIsMetricLoading] = useState<boolean>(false);
	const [isMonthPickerOpen, setIsMonthPickerOpen] = useState(false);
	const [selectedMonthData, setSelectedMonthData] = useState({ month: null, year: null, monthName: null, monthShortName: null, minDate: null as string | null });
	const pickerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (props.publisherProfile != undefined && props.selectedNewsletterId != undefined && props.isEarnActive) {
			getData();
		}
	}, [props.publisherProfile]);

	useEffect(() => {
		if (props.newsletterSwitched && props.isEarnActive) {
			getData();
		}
	}, [props.newsletterSwitched]);

	useEffect(() => {
		if (isMonthPickerOpen) {
			document.addEventListener("mousedown", handlePickerClickOutside);
		} else {
			document.removeEventListener("mousedown", handlePickerClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handlePickerClickOutside);
		};
	}, [isMonthPickerOpen]);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(referralDetail?.referral_url ?? "");
		setToastState({ status: "success", title: null, message: "Referral link copied to clipboard." });
	};

	const assetsOnClick = () => {
		analyticsManager.recordEvent(kAnalyticsConstants.Earn.assetsViewed, { newsletter_id: props.selectedNewsletterId });
		window.open("https://jeans-itch-5me.craft.me/xrPw4KLEJhw2MF", "_blank");
	};

	const getData = () => {
		setIsLoading(true);
		networkManager
			.getReferralLink(props.selectedNewsletterId)
			.then((referralDetail) => {
				setReferralDetail(referralDetail);

				setSelectedMonthData({
					...selectedMonthData,
					minDate: referralDetail.active_from,
				});

				return networkManager.getReferralOverviewStats(props.selectedNewsletterId, selectedMonthData);
			})
			.then((referralOverviewResponse) => {
				setOverviewStatResponse(referralOverviewResponse);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't get your referral link. Please try again later." });
			});
	};

	const getCardIcon = (statId) => {
		switch (statId) {
			case "earnings":
				return BanknotesIcon;
			case "price":
				return CurrencyDollarIcon;
			case "referrals":
				return TrophyIcon;
			case "referral_traffic":
				return CursorArrowRaysIcon;
			default:
				return CheckIcon;
		}
	};

	const onPickerDateChange = (dateData) => {
		if (dateData) {
			setSelectedMonthData({
				...dateData,
				minDate: selectedMonthData.minDate,
			});
			setIsMetricLoading(true);
			networkManager
				.getReferralOverviewStats(props.selectedNewsletterId, dateData)
				.then((referralOverviewResponse) => {
					setOverviewStatResponse(referralOverviewResponse);
					setIsMetricLoading(false);
					setIsLoading(false);
				})
				.catch((_error) => {
					setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't load your stats. Please try again later." });
				});
		}
	};

	const handlePickerClickOutside = (event: MouseEvent) => {
		if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
			setIsMonthPickerOpen(false);
		}
	};

	return (
		<div>
			{props.loadingProfile || !props.isEarnActive || isLoading ? (
				<LoadingView isLoading={props.loadingProfile || isLoading} />
			) : (
				<div>
					<Toast toastState={toastState} />
					<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px]">
						<div className="flex flex-col sm:flex-row gap-4 sm:gap-5 items-start sm:items-center">
							<div className="flex flex-col gap-1 items-left">
								<div className="text-primary-light font-regular font-primary text-fs-heading-small">Your referral link</div>
								<div className="text-primary-light/50 font-regular font-primary text-fs-body-small">Use this link when promoting Meco in your newsletter and social channels. Please use the assets and guidelines provided when featuring Meco in your newsletter. Any successful referrals and earnings will be displayed below on your monthly dashboard.</div>
							</div>
							<div className="flex flex-col gap-3 items-center w-full sm:w-auto">
								<input value={referralDetail?.referral_url ?? ""} disabled={true} autoFocus={false} className="w-full sm:w-[300px] flex-grow bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50" />
								<div className="flex flex-col sm:flex-row w-full justify-between gap-3">
									<button className="text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center border-solid border-[1px] border-primary-light/50 whitespace-nowrap" onClick={() => copyToClipboard()}>
										Copy referral link
									</button>
									<button className="text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => assetsOnClick()}>
										View assets
									</button>
								</div>
							</div>
						</div>
					</div>
					{referralDetail && referralDetail.active_price && (
						<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px] font-regular font-primary text-fs-body flex flex-row items-center gap-3">
							<Icon icon={CurrencyDollarIcon} color="green" variant="light" size="sm" />
							<div>Current payout per referral is {referralDetail?.active_price}</div>
						</div>
					)}
					<div className="flex flex-col sm:flex-row justify-between items-left sm:items-center pb-3 gap-3 sm:gap-0">
						<div className="font-medium font-primary text-fs-heading">Your Monthly Stats</div>
						<div>
							<MonthInput size="small" selected={selectedMonthData} setShowMonthPicker={setIsMonthPickerOpen} showMonthPicker={isMonthPickerOpen} />
							{isMonthPickerOpen ? <MonthPicker pickerRef={pickerRef} setIsOpen={setIsMonthPickerOpen} selected={selectedMonthData} onChange={(dateData) => onPickerDateChange(dateData)} /> : null}
						</div>
					</div>
					<Grid numItemsMd={2} numItemsLg={4} className="gap-x-6 gap-y-6">
						{overviewStatResponse &&
							overviewStatResponse.overview_stats
								.sort((a, b) => a.sort_index - b.sort_index)
								.map((stat) => {
									return (
										<Card key={stat.id}>
											<Flex alignItems="start">
												<div>
													<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
													<div className="truncate mt-4">
														<Title>{stat.title}</Title>
														{isMetricLoading ? <Spinner className="loading-spinner spinner-border-sm mt-3 w-[10px]" animation="border" role="status" /> : <Metric>{stat.value}</Metric>}
													</div>
												</div>
												{stat.status && (
													<div>
														<Badge size="md" color={stat.status_color}>
															{stat.status.toUpperCase()}
														</Badge>
													</div>
												)}
												{stat.hint && (
													<div>
														<Tooltip message={stat.hint}>
															<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
														</Tooltip>
													</div>
												)}
											</Flex>
										</Card>
									);
								})}
					</Grid>
				</div>
			)}
		</div>
	);
};

export default withRouter(EarnDashboardView);
