import { Modal, Button } from "react-bootstrap";

function AlertView(props) {
	return (
		<div>
			<Modal show={props.showModal} onHide={props.setShowModal}>
				<Modal.Header>
					<Modal.Title className="alert-title">{props.alertTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="alert-description">{props.alertDescription}</Modal.Body>
				<Modal.Footer>
					{props.dismissButtonTitle != null ? (
						<Button variant="secondary" onClick={props.setShowModal}>
							{props.dismissButtonTitle}
						</Button>
					) : null}
					{props.alertButtonTitle != null ? (
						<Button variant="primary" className="alert-button" onClick={props.alertButtonAction != null ? props.alertButtonAction : props.setShowModal}>
							{" "}
							{props.alertButtonTitle}{" "}
						</Button>
					) : null}
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default AlertView;
