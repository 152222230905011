import { useState, useEffect } from "react";
import Toast, { ToastState } from "../utils/DesignSystem/Toast";
import * as networkManager from "../networking/NetworkManager";
import { PUBBReferralOverviewStatsResponse } from "../models/Models";
import LoadingView from "../utils/LoadingView";
import { Badge, Card, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text } from "@tremor/react";
import moment from "moment";
import { withRouter } from "react-router-dom";

const EarnPayouts = (props) => {
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [overviewStatResponseArray, setOverviewStatResponseArray] = useState<[PUBBReferralOverviewStatsResponse] | null>(null);

	interface RowObject {
		date?: string;
		earnings?: string | null;
		price?: string | null;
		referrals?: string | null;
		earnings_status?: string | null;
	}

	const headerDictionary: RowObject = {
		date: "Date",
		earnings: "Earnings",
		price: "Avg. Earnings per Referral",
		referrals: "Referrals",
		earnings_status: "Payment Status",
	};

	useEffect(() => {
		if (props.publisherProfile != undefined && props.selectedNewsletterId != undefined && props.isEarnActive) {
			getData();
		}
	}, [props.publisherProfile]);

	useEffect(() => {
		if (props.newsletterSwitched && props.isEarnActive) {
			getData();
		}
	}, [props.newsletterSwitched]);

	const getData = () => {
		networkManager
			.getReferralPayouts(props.selectedNewsletterId)
			.then((overviewResponseArray) => {
				setIsLoading(false);
				setOverviewStatResponseArray(overviewResponseArray);
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.response.status  !== 404) {
					setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't get your referral link. Please try again later." });
				}
			});
	};

	const getStatusColor = (status?: string | null) => {
		switch (status) {
			case "pending":
				return "amber";
			case "paid":
				return "green";
			case "failed":
				return "red";
			default:
				return "transparent";
		}
	};

	const getDateString = (date?: string | null) => {
		const inputDateString = date;
		const inputMoment = moment(inputDateString, "YYYY-MM");
		const outputString = inputMoment.format("MMMM YYYY");
		return outputString;
	};

	const generateRows = (responseArray: PUBBReferralOverviewStatsResponse[]): RowObject[] => {
		const rowDict: RowObject[] = [];

		responseArray.forEach((response) => {
			const dict: RowObject = {
				date: getDateString(response.date),
				earnings: null,
				price: null,
				referrals: null,
				earnings_status: null,
			};

			Object.keys(headerDictionary).forEach((key: string) => {
				response.overview_stats.forEach((metric) => {
					if (key === metric.id) {
						dict[key] = metric.value;
					}
					if (key === "earnings_status" && metric.id === "earnings") {
						dict[key] = metric.status;
					}
				});
			});

			const dictContainsAllKeys = Object.keys(headerDictionary).every((key: string) => {
				return key in dict;
			});

			if (dictContainsAllKeys) {
				rowDict.push(dict);
			}
		});

		return rowDict;
	};

	return (
		<div>
			{props.loadingProfile || !props.isEarnActive || isLoading ? (
				<LoadingView isLoading={props.loadingProfile || isLoading} />
			) : (
				<div>
					<Toast toastState={toastState} />
					<Card>
						<Table>
							<TableHead>
								<TableRow>
									{Object.keys(headerDictionary).map((key: string) => (
										<TableHeaderCell key={key} className="text-center">
											{headerDictionary[key]}
										</TableHeaderCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{overviewStatResponseArray &&
									generateRows(overviewStatResponseArray) &&
									generateRows(overviewStatResponseArray).map((dict) => (
										<TableRow key={dict["date"]}>
											{Object.keys(dict).map((row) => (
												<TableCell className="text-center">{row == "earnings_status" ? <Badge color={getStatusColor(dict[row])}>{dict[row]?.toUpperCase() ?? "-"}</Badge> : <Text>{dict[row]}</Text>}</TableCell>
											))}
										</TableRow>
									))}
							</TableBody>
						</Table>
					</Card>
				</div>
			)}
		</div>
	);
};

export default withRouter(EarnPayouts);
