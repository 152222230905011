import React, { Component, useEffect, useState } from "react";
import styles from "./Integrations.module.scss";
import { withRouter } from "react-router-dom";
import { Form, Col, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import LoadingView from "../utils/LoadingView";
import ToastView from "../utils/ToastView";
import * as networkManager from "../networking/NetworkManager";
import * as analyticsManager from "../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";
import SetupWizardModal from "./SetupWizardModal";

const Integrations = (props) => {
	const [isLoading, setLoading] = useState(false);
	const [integrationActionLoadingMessage, setIntegrationActionLoadingMessage] = useState(null);
	const [toastMessage, setToastMessage] = useState(null);
	const [isErrorIntegrating, setErrorIntegrating] = useState(false);
	const [integrations, setIntegrations] = useState(undefined);
	const [isShowSetupWizard, setSetupWizard] = useState(false);

	useEffect(() => {
		if (props.publisherProfile != undefined) {
			getIntegrations();
		}
	}, [props.publisherProfile]);

	useEffect(() => {
		if (props.newsletterSwitched) {
			getIntegrations();
		}
	}, [props.newsletterSwitched]);

	function getIntegrations() {
		setIntegrations(undefined);
		networkManager
			.getIntegrations(props.selectedNewsletterId)
			.then((integrations) => {
				setIntegrations(integrations);
			})
			.catch((error) => {
				setIntegrations(null);
			});
	}

	function returnAppropriateButton() {
		if (integrations == null) {
			//error
			return (
				<button disabled={true} className="text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center bg-success-green whitespace-nowrap">
					Set up an integration
				</button>
			);
		} else if (integrations.newsletter_integration.integration_id) {
			//view integration
			return (
				<button className="text-success-green font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center border-solid border-[1px] border-success-green whitespace-nowrap" onClick={() => setSetupWizard(true)}>
					View integration
				</button>
			);
		} else {
			//set up integration
			return (
				<button className="text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => setSetupWizard(true)}>
					Set up an integration
				</button>
			);
		}
	}

	function integrationSubmitted(formValue) {
		setLoading(true);
		setIntegrationActionLoadingMessage("Setting up integration");
		setSetupWizard(false);
		networkManager
			.postIntegration(props.selectedNewsletterId, formValue)
			.then((integrations) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Integrations.added);
				setToastMessage("Integration created");
				setIntegrations(integrations);
				setLoading(false);
				setIntegrationActionLoadingMessage(null);
				setTimeout(function () {
					setToastMessage(null);
				}, 3000);
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Integrations.addedError);
				setLoading(false);
				setIntegrationActionLoadingMessage(null);
				setErrorIntegrating(true);
				setToastMessage("We were unable to create your integration. Please try again or contact support.");
				setTimeout(function () {
					setToastMessage(null);
					setErrorIntegrating(false);
				}, 3000);
			});
	}

	function integrationDeleted(deleteQuietly) {
		setLoading(true);
		setIntegrationActionLoadingMessage("Removing integration");
		if (deleteQuietly == undefined) {
			setSetupWizard(false);
		}
		networkManager
			.deleteIntegration(props.selectedNewsletterId)
			.then((integrations) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Integrations.removed);
				setToastMessage("Integration removed. Make sure to turn on your alerts.");
				setIntegrations(integrations);
				setLoading(false);
				setIntegrationActionLoadingMessage(null);
				setTimeout(function () {
					setToastMessage(null);
				}, 3000);
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Integrations.removedError);
				setLoading(false);
				setIntegrationActionLoadingMessage(null);
				setErrorIntegrating(true);
				setToastMessage("We were unable to remove your integration. Please try again or contact support.");
				setTimeout(function () {
					setToastMessage(null);
					setErrorIntegrating(false);
				}, 3000);
			});
	}

	return (
		<div>
			{props.loadingProfile || typeof integrations == "undefined" ? (
				<LoadingView isLoading={props.loadingProfile || typeof integrations == "undefined"} />
			) : (
				<div>
					<LoadingView isLoading={isLoading} loadingMessage={integrationActionLoadingMessage} />
					<ToastView isDisplayToast={toastMessage != null} message={toastMessage} isWarning={isErrorIntegrating} />
					<SetupWizardModal show={isShowSetupWizard} setShow={() => setSetupWizard(false)} integrations={integrations} onSubmitIntegration={(formValue) => integrationSubmitted(formValue)} onDeleteIntegration={(deleteQuietly) => integrationDeleted(deleteQuietly)} />
					{integrations.newsletter_integration.error && <div className={styles.off_warning}>[Important] There is an issue with your integration which may prevent new subscribers being added directly to your newsletter list. Please reconfigure your integration.</div>}
					<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px]">
						<div className="flex flex-col sm:flex-row gap-4 sm:gap-5 items-start sm:items-center justify-between">
							<div className="flex flex-col gap-2">
								<div className="text-primary-light font-regular font-primary text-fs-heading-small">Automatically add new subscribers to your newsletter list</div>
								<div className="text-primary-light/50 font-regular font-primary text-fs-body-small">We integrate with a number of newsletter publishing platforms so you can get new subscribers from Meco sent directly to your newsletter list.</div>
							</div>
							{returnAppropriateButton()}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default withRouter(Integrations);
