import styles from "./Layout.module.scss";
import { BrowserRouter as Router, Route, Link, useLocation, useHistory } from "react-router-dom";
import mecoLogo from "../../assets/images/meco_logo.svg";
import React, { useState, useEffect } from "react";
import * as networkManager from "../../networking/NetworkManager";
import { kLocalStorageKeys } from "../../constants/kLocalStorageKeys";
import { Dropdown } from "react-bootstrap";
import * as analyticsManager from "../../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../../constants/AnalyticsConstants";

function Layout({ children }) {
	const [isLoading, setLoading] = useState(false);
	const [activeNewsletterId, setActiveNewsletterId] = useState(null);
	const [publisherProfile, setPublisherProfile] = useState(null);
	const [isGrowActive, setGrowActive] = useState(null);
	const [isEarnActive, setEarnActive] = useState(null);
	const [isGrowAffiliateActive, setGrowAffiliateActive] = useState(null);
	const [isNewsletterSwitched, setNewsletterSwitched] = useState(null);
	const [paypalAccount, setPaypalAccount] = useState(null);

	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		networkManager
			.getProfile()
			.then((profile) => {
				let newsletterIdArray = profile.newsletters.map((x) => x.id);
				let savedActiveNewsletterId = localStorage.getItem(kLocalStorageKeys.User.activeNewsletterId);
				var activeNewsletterId = null;
				if (newsletterIdArray.includes(parseInt(savedActiveNewsletterId))) {
					activeNewsletterId = localStorage.getItem(kLocalStorageKeys.User.activeNewsletterId);
				} else {
					activeNewsletterId = profile.newsletters[0].id;
				}
				determineEarnStatus(profile, activeNewsletterId);

				const paypalEmailAddress = profile.newsletters.find((x) => x.id === parseInt(activeNewsletterId))?.paypal_account;
				setPaypalAccount(paypalEmailAddress);

				setActiveNewsletterId(activeNewsletterId);
				setPublisherProfile(profile);
				setLoading(false);
			})
			.catch((error) => {
				localStorage.clear();
				window.history.replaceState(null, "");
				history.push("/login", { sessionErrorMessage: "Please sign in again." });
			});
	}, []);

	useEffect(() => {
		if (!activeNewsletterId) {
			return;
		}
		localStorage.setItem(kLocalStorageKeys.User.activeNewsletterId, activeNewsletterId);
	}, [activeNewsletterId]);

	function newsletterChanged(newsletterId) {
		setActiveNewsletterId(newsletterId);
		setNewsletterSwitched(newsletterId);
		determineEarnStatus(publisherProfile, newsletterId);
		analyticsManager.recordEvent(kAnalyticsConstants.App.newsletterSwitched, { switched_newsletter_id: newsletterId });
	}

	function determineEarnStatus(profile, selectedNewsletterId) {
		//Determine Earn
		const earnActiveValue = profile.newsletters.find((x) => x.id === parseInt(selectedNewsletterId))?.is_referral_active;
		const earnActive = Boolean(earnActiveValue);
		setEarnActive(earnActive);

		//Determine Grow
		const growActiveValue = profile.newsletters.find((x) => x.id === parseInt(selectedNewsletterId))?.one_click_subscribe_method;
		const growActive = Boolean(growActiveValue);
		setGrowActive(growActive);

		//Determine Grow Affilaite
		const growAffiliateActiveValue = profile.newsletters.find((x) => x.id === parseInt(selectedNewsletterId))?.is_affiliate_active;
		const growAffiliateActive = Boolean(growAffiliateActiveValue);
		setGrowAffiliateActive(growAffiliateActive);
	}

	function onLogoClick() {
		history.push("/");
	}

	function onLogOut() {
		localStorage.clear();
		window.history.replaceState(null, "");
		history.push("/login");
	}

	function onGrowSubscribersClick() {
		history.push("/grow/subscribers");
	}

	function onGrowAlertsClick() {
		history.push("/grow/alerts");
	}

	function onGrowIntegrationsClick() {
		history.push("/grow/integrations");
	}
	
	function onEarnDashboardClick() {
		history.push("/earn/dashboard");
	}

	function onPayoutsClick() {
		history.push("/earn/payouts");
	}

	function onGrowAffiliateDashboardClick() {
		history.push("/grow/affiliate/dashboard");
	}

	function onGrowAffiliatePaymentsClick() {
		window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + encodeURI(publisherProfile?.email));
	}

	function onPaymentSettingsClick() {
		const queryParams = new URLSearchParams(history.location.search);
		const paymentSettingsParam = queryParams.get("paymentSettings");
		if (!paymentSettingsParam) {
			queryParams.set("paymentSettings", "true");
			history.replace({
				search: queryParams.toString(),
			});
		}
	}

	function setPaypalAccountAddress(paypalAddress) {
		setPaypalAccount(paypalAddress);
	}

	return (
		<div>
			<div className="container">
				<div className={styles.navigation_header}>
					<div className={`${styles.publishers_logo} no-select`} onClick={() => onLogoClick()}>
						<div className={styles.meco_logo}>Meco </div>
						<div className={styles.for_publishers}>for Publishers</div>
					</div>

					{publisherProfile && (
						<Dropdown style={{ marginTop: "-6px" }}>
							<Dropdown.Toggle id="option-button" bsPrefix="p-0">
								<div className={styles.newsletter_name}>
									{publisherProfile.newsletters.filter((x) => x.id == activeNewsletterId)[0].name}
									<div className={styles.down_arrow}></div>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{publisherProfile.newsletters.length > 1 &&
									publisherProfile.newsletters.map((newsletter, idx) =>
										activeNewsletterId == newsletter.id ? (
											<Dropdown.Item eventKey={newsletter.id} key={newsletter.id} active onClick={() => newsletterChanged(newsletter.id)}>
												{" "}
												{newsletter.name}{" "}
											</Dropdown.Item>
										) : (
											<Dropdown.Item eventKey={newsletter.id} key={newsletter.id} onClick={() => newsletterChanged(newsletter.id)}>
												{newsletter.name}
											</Dropdown.Item>
										)
									)}

								{publisherProfile.newsletters.length > 1 && <Dropdown.Divider />}

								<Dropdown.Item eventKey="log_out" onClick={() => onLogOut()}>
									Log out
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
				</div>
				<ul className={styles.navigation_menu}>
					<li>
						<Link to={isEarnActive ? "/earn" : "/earn/join"} className={location.pathname.includes("/earn") ? styles.active_menu : null}>
							Earn with Meco
						</Link>
					</li>
					<li>
						<Link to={isGrowActive ? "/grow" : "/grow/join"} className={location.pathname.includes("/grow") ? styles.active_menu : null}>
							Grow with Meco
						</Link>
					</li>
				</ul>
				<div className={styles.horizontal_line}></div>
				{location.pathname.includes("/earn") && isEarnActive && (
					<div className="flex flex-row gap-3 pt-6 bg-card p-3 mt-3 rounded-xl">
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/earn/dashboard" ? "bg-success-green" : "bg-card"}`} onClick={() => onEarnDashboardClick()}>
							Dashboard
						</button>
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/earn/payouts" ? "bg-success-green" : "bg-card"}`} onClick={() => onPayoutsClick()}>
							Payouts
						</button>
						<div className="relative inline-block">
							<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center bg-card`} onClick={() => onPaymentSettingsClick()}>
								Payment Settings
							</button>
							{paypalAccount == null && <div className="absolute top-1 right-1">
								<div className="w-[12px] h-[12px] bg-brand-red text-white flex items-center justify-center rounded-full">
									<span className="font-medium font-primary text-fs-body-extra-small ">!</span>
								</div>
							</div> }
					
						</div>
					</div>
				)}
				{location.pathname.includes("/grow") && isGrowActive && (
					<div className="flex flex-row gap-3 pt-6 bg-card p-3 mt-3 rounded-xl">
						{ isGrowAffiliateActive &&
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/grow/affiliate/dashboard" ? "bg-success-green" : "bg-card"}`} onClick={() => onGrowAffiliateDashboardClick()}>
							Dashboard
						</button> }
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/grow/subscribers" ? "bg-success-green" : "bg-card"}`} onClick={() => onGrowSubscribersClick()}>
							Your subscribers
						</button>
						{ isGrowAffiliateActive &&
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/grow/affiliate/payments" ? "bg-success-green" : "bg-card"}`} onClick={() => onGrowAffiliatePaymentsClick()}>
							Payments
						</button> }
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/grow/alerts" ? "bg-success-green" : "bg-card"}`} onClick={() => onGrowAlertsClick()}>
							Alerts
						</button>
						<button className={`whitespace-nowrap text-primary-light font-medium font-primary text-fs-body-small sm:text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 inline-flex items-center ${location.pathname === "/grow/integrations" ? "bg-success-green" : "bg-card"}`} onClick={() => onGrowIntegrationsClick()}>
							Integrations
						</button>
					</div>
				)}
			</div>
			<div className={styles.main_content}>
				<div className="container">{React.cloneElement(children, { loadingProfile: isLoading, publisherProfile: publisherProfile, selectedNewsletterId: activeNewsletterId, newsletterSwitched: isNewsletterSwitched, isGrowActive: isGrowActive, isEarnActive: isEarnActive, isGrowAffiliateActive: isGrowAffiliateActive, paypalAccount: paypalAccount, setPaypalAccount: (address) => setPaypalAccountAddress(address) })}</div>
			</div>
			<footer className={styles.bar}>
				<div className="container">
					<div className="row">
						<div className="col-12 pb-3">
							<img className={styles.logo} style={{ height: "12px", display: "inline" }} src={mecoLogo} />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<p>
								©{new Date().getFullYear()}&nbsp; Meco - All rights reserved
								<br />
								<a href="https://www.meco.app/privacy" target="_blank">
									Privacy Policy
								</a>{" "}
								&middot;{" "}
								<a href="https://www.meco.app/terms" target="_blank">
									Terms &amp; Conditions
								</a>{" "}
								&middot; <a href="mailto:team@meco.app">Get In Touch</a> &middot;{" "}
								<a href="https://twitter.com/meco_app?ref_src=twsrc%5Etfw" target="_blank">
									Follow us&nbsp;
									<svg style={{ display: "inline" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-twitter" viewBox="0 0 16 16">
										<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
									</svg>
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Layout;
