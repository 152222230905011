import { Route, Redirect } from "react-router-dom";
import { kLocalStorageKeys } from "../../constants/kLocalStorageKeys";
import Layout from '../Layout/Layout';
import HomeLayout from "./HomeLayout";

function HomeProtectedRoute({ component: Component, ...rest }) {

    return (
        <Route
        {...rest}
        render={(props) => {
            if (localStorage.getItem(kLocalStorageKeys.User.token) !== null) {
               
                return <Layout><HomeLayout /></Layout>;

            } else {
                return (<Redirect to={{ pathname: "/login"}} />);
            }
        }}

        />
    );
}

export default HomeProtectedRoute;
