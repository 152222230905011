import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

function HomeLayout({ children, ...layoutProps }) {
	const history = useHistory();

	useEffect(() => {
		const isGrowActive = layoutProps.isGrowActive;
        const isEarnActive = layoutProps.isEarnActive;

        if (isGrowActive == null || isEarnActive == null) {
            return;
        }

        if (isGrowActive == true && isEarnActive == true) {
            history.push("/earn");
            return;
        }

        if (isGrowActive == true && isEarnActive == false) {
            history.push("/grow");
            return;
        }

        history.push("/earn");

        return

	}, [layoutProps.isGrowActive, layoutProps.isEarnActive]);

	return (
		<div></div>
	);
}

export default HomeLayout;
