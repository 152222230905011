import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const GrowJoin = (props) => {
	const history = useHistory();

	useEffect(() => {
		const isGrowActive = props.isGrowActive;
		if (isGrowActive) {
			history.push("/grow");
		}
	}, [props.isGrowActive]);

	const onSubmitFormClick = () => {
		window.open("https://tally.so/r/mJ1kNd", "_blank");
	};

	return (
		<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px]">
			<div className="flex flex-col sm:flex-row gap-4 sm:gap-5 items-start sm:items-center">
				<div className="flex flex-col gap-2">
					<div className="text-primary-light font-regular font-primary text-fs-heading-small">Promote your newsletter on the Meco app</div>
					<div className="text-primary-light/50 font-regular font-primary text-fs-body-small">We drive thousands of subscribers to newsletters every month through the Meco app. If you’d like your newsletter to be featured to good-fit readers on Meco please submit your details and we’ll get back to you.</div>
				</div>
				<button className="h-[40px] w-[200px] text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => onSubmitFormClick()}>
					<div>Apply now</div>
				</button>
			</div>
		</div>
	);
};

export default GrowJoin;
