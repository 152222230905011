import "./NotFound.scss";
import { withRouter } from "react-router";
import { Modal, Button } from 'react-bootstrap';
import './Stars.sass';
import mecoLogo from '../../assets/images/meco_logo.svg';

export const NotFound = (props) => {


	function goHome() {
		props.history.push("/")
	}

	return (
		<div>
			<Modal size="" className="not-found-modal" contentClassName="not-found-pane" aria-labelledby="contained-modal-title-vcenter" centered show={true}>
				<Modal.Body>
					<div className="not-found-header">Oops! Lost in space?</div>
					<div className="not-found-body">Could not find the page you're looking for</div>
					<Button className="not-found-cta" onClick={() => goHome()}>Back to Home</Button>
				</Modal.Body>
			</Modal>
			<div className="stars-container" show={true}>
				<div className="sky">
					<div className="stars"></div>
					<div className="stars1"></div>
					<div className="shooting-stars"></div>
				</div>
			</div>
		</div>
	);
};

export default withRouter(NotFound);
