class CustomError extends Error {
    constructor(message, statusCode) {
     super(message)
     this.statusCode = statusCode
    }
}

export const kErrorConstants = {
    prospectEmail: {
        noListedEmailsError: Error('GMProspectMailsWorker/listMessages: no listed emails'),
        fullMailFetchError: Error('GMProspectMailsWorker/getMessages: couldnt fetch full emails')
    },
    history: {
        noSyncModelError: Error('GMSyncMailsWorker/listHistory: no syncmodel')
    },
    changeState: {
        getFiltersError: Error('GMFilterService/getAlreadyUsedFilters: couldnt get filters'),
        deleteAllFiltersError: Error('GMFilterService/deleteFilters: couldnt delete all filters')
    },
    signUp: {
        existingAccount: new CustomError("There is already an account with this mail address", 403)
    },
    logIn: {
        nonExistantAccount: new CustomError("Account not found", 404)
    }
}