export class NLProfile {
    id!: string;
    first_name?: string;
    last_name?: string;
    email!: string;
    created_at!: Date;
    picture_url?: string;
    locale?: string;
    notifications?: any;
    subscriptions?: any;
    internal_trial?: any;
    premium_status?: number;
    premium_enabled?: boolean;
    is_profile_setup!: boolean;
    mailbox_profiles!: NLMailboxProfile[];

    constructor(profile: NLProfile) {
        Object.assign(this, profile);

        if (profile.mailbox_profiles) {
            this.mailbox_profiles = profile.mailbox_profiles.map(
                (mailboxProfile: NLMailboxProfile) => new NLMailboxProfile(mailboxProfile)
            );
        }
    }
}

export class NLMailboxProfile {
    id!: string;
    email_address!: string;
    is_default?: boolean;
    is_error?: boolean;
    is_profile_setup?: boolean;
    name?: string;
    type!: string;

    constructor(mailboxProfile: NLMailboxProfile) {
        Object.assign(this, mailboxProfile);
    }

}

export class PUBReferralDetail {
    referral_url!: string;
    active_from!: string;
    active_price!: string;

    constructor(referralDetail: PUBReferralDetail) {
        Object.assign(this, referralDetail);
    }

}

export class PUBBReferralOverviewStatsResponse {
    date!: string;
    overview_stats!: PUBBReferralOverviewStat[];

    constructor(overviewStatsResponse: PUBBReferralOverviewStatsResponse) {
        Object.assign(this, overviewStatsResponse);

        if (overviewStatsResponse.overview_stats) {
            this.overview_stats = overviewStatsResponse.overview_stats.map(
                (overview_stats: PUBBReferralOverviewStat) => new PUBBReferralOverviewStat(overview_stats)
            );
        }
    }

}

export enum OverviewStatType {
    Metric = 'metric',
    Bar = 'bar',
    Line = 'line',
}

export type OverviewStatDataPoint = {
    date: string;
    value?: number;
};

export class PUBBReferralOverviewStat {
    id!: string;
    sort_index!: number;
    title!: string;
    value?: string;
    status?: string;
    status_color?: string;
    hint?: string;
    type: OverviewStatType;
    data_points: OverviewStatDataPoint[];

    constructor(overviewStats: PUBBReferralOverviewStat) {
        this.sort_index = overviewStats.sort_index !== undefined ? overviewStats.sort_index : 0;
        this.type = overviewStats.type || OverviewStatType.Metric;
        this.data_points = overviewStats.data_points || [];
        Object.assign(this, overviewStats);
    }

}

export class PUBAffiliateDetail {
    cc_on_file!: boolean;
    active_from!: string;
    active_price!: string;

    constructor(affiliateDetail: PUBAffiliateDetail) {
        Object.assign(this, affiliateDetail);
    }

}

export class PUBBAffiliateOverviewStatsResponse {
    start_date!: string;
    end_date!: string;
    overview_stats!: PUBBReferralOverviewStat[];

    constructor(overviewStatsResponse: PUBBAffiliateOverviewStatsResponse) {
        Object.assign(this, overviewStatsResponse);

        if (overviewStatsResponse.overview_stats) {
            this.overview_stats = overviewStatsResponse.overview_stats.map(
                (overview_stats: PUBBReferralOverviewStat) => new PUBBReferralOverviewStat(overview_stats)
            );
        }
    }

}