import { Route, Redirect } from "react-router-dom";
import { kLocalStorageKeys } from "../constants/kLocalStorageKeys";

function DeepLinkRoute({ component: Component, noSideBar, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => {
                const url = props.location.pathname
				const base64EncodedData = url.split("/").pop();
				const urlDecodedData = decodeURIComponent(atob(base64EncodedData));
                try {
                    const magicLinkObject = JSON.parse(urlDecodedData);
                    props.history.push("/magic-link", {magicLinkObject: magicLinkObject, email: magicLinkObject.email_address });
                } catch (error) {
                    props.history.push("/login");
                }
			}}
		/>
	);
}

export default DeepLinkRoute;
