import { FC, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

export interface ToastState {
	status: string | null;
	title: string | null;
	message: string | null;
}

interface ToastProps {
	toastState: ToastState;
}

const Toast: FC<ToastProps> = ({ toastState }) => {
	useEffect(() => {
		const { status, title, message } = toastState;

		if (message) {

            var messageAlpha = "100";
            if (title) {
                messageAlpha = "50";
            }

			if (status == "error") {

				toast.error(
					() => (
						<div className="font-primary">
							<p className="font-medium text-fs-heading-small text-white m-0">{title}</p>
							<p className={`font-regular text-fs-body m-0 text-white/${messageAlpha}`}>{message}</p>
						</div>
					),
					{ id: message, style: { backgroundColor: "#000518", border: "1px rgba(238, 238, 238, 0.2) solid"} }
				);

			} else {

				toast.success(
					() => (
						<div className="font-primary">
							<p className="font-medium text-fs-heading-small text-white m-0">{title}</p>
							<p className={`font-regular text-fs-body m-0 text-white/${messageAlpha}`}>{message}</p>
						</div>
					),
					{ id: message, style: { backgroundColor: "#000518", border: "1px rgba(238, 238, 238, 0.2) solid"} }
				);

			}

		}
	}, [toastState]);

	return (
		<div>
			<Toaster position="top-center" />
		</div>
	);
};

export default Toast;
