import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

function GrowAffiliateLayout({ children, ...layoutProps }) {
	const history = useHistory();

	useEffect(() => {
		const isGrowAffiliateActive = layoutProps.isGrowAffiliateActive;
		if (isGrowAffiliateActive == false) {
			history.push("/grow/subscribers");
		}
	}, [layoutProps.isGrowAffiliateActive]);

	return (
		<div>
			<div className="container">{React.cloneElement(children, { ...layoutProps })}</div>
		</div>
	);
}

export default GrowAffiliateLayout;
