import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

function GrowLayout({ children, ...layoutProps }) {
	const history = useHistory();

	useEffect(() => {
		const isGrowActive = layoutProps.isGrowActive;
		if (isGrowActive == false) {
			history.push("/grow/join");
		}
	}, [layoutProps.isGrowActive]);

	return (
		<div>
			<div className="container">{React.cloneElement(children, { ...layoutProps })}</div>
		</div>
	);
}

export default GrowLayout;
