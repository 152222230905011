export default function Tooltip({ message, children }) {
	return (
		<div className="relative flex flex-col items-center group">
			{children}
			<div className="w-[200px] absolute bottom-2 flex flex-col items-center hidden mb-6 group-hover:flex">
				<span className="relative z-10 whitespace-no-wrap shadow-lg bg-white p-3 font-regular font-primary text-fs-body rounded-[10px] text-secondary-dark leading-[20px]">{message}</span>
				<div className="w-3 h-3 -mt-2 rotate-45 bg-white"></div>
			</div>
		</div>
	);
}
