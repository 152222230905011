export const kLocalStorageKeys = {
    Session: {
        sessionStart: 'sessionStart',
        utmCampaign: 'utmCampaign',
        onboardingNewsletterPickerState: 'onboardingNewsletterPickerState',
        onboardingCategorySelectionState: 'onboardingCategorySelectionState',
    },
    User: {
        token: 'token',
        profile: 'profile',
        activeNewsletterId: 'activeNewsletterId'
    }
}