import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Card, Badge, Flex, Metric, Title, Icon, DateRangePicker, DateRangePickerValue, LineChart, BarChart } from "@tremor/react";
import { UserGroupIcon, CurrencyDollarIcon, ChartBarIcon, ExclamationTriangleIcon, BanknotesIcon, CheckIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import LoadingView from "../../../../utils/LoadingView";
import Toast, { ToastState } from "../../../../utils/DesignSystem/Toast";
import * as networkManager from "../../../../networking/NetworkManager";
import { PUBAffiliateDetail, PUBBAffiliateOverviewStatsResponse, OverviewStatType, PUBBReferralOverviewStat } from "../../../../models/Models";
import * as analyticsManager from "../../../../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../../../../constants/AnalyticsConstants";
import Tooltip from "../../../../utils/Tooltip";
import { Spinner } from "react-bootstrap";
import { enGB } from "date-fns/locale";
import moment from "moment";

const GrowAffiliateDashboard = (props) => {
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});
	const [affiliateDetail, setAffiliateDetail] = useState<PUBAffiliateDetail | null>(null);
	const [overviewStatResponse, setOverviewStatResponse] = useState<PUBBAffiliateOverviewStatsResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMetricLoading, setIsMetricLoading] = useState<boolean>(false);
	const [selectedMonthData, setSelectedMonthData] = useState<DateRangePickerValue>({selectValue: "t"});

	useEffect(() => {
		if (props.publisherProfile != undefined && props.selectedNewsletterId != undefined && props.isGrowActive && props.isGrowAffiliateActive) {
			getData();
		}
	}, [props.publisherProfile]);

	useEffect(() => {
		if (props.newsletterSwitched && props.isGrowActive && props.isGrowAffiliateActive) {
			getData();
		}
	}, [props.newsletterSwitched]);

	const getData = () => {
		setIsLoading(true);
		networkManager
			.getAffiliateDetail(props.selectedNewsletterId)
			.then((affiliateDetail) => {
				setAffiliateDetail(affiliateDetail);
				const defaultQuery = getDefaultQueryDate(affiliateDetail.active_from)
				setSelectedMonthData(defaultQuery);
				return networkManager.getAffiliateOverviewStats(props.selectedNewsletterId, defaultQuery.from!, defaultQuery.to!);
			})
			.then((affiliateOverviewResponse) => {
				setOverviewStatResponse(affiliateOverviewResponse);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
				setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't get your referral link. Please try again later." });
			});
	};

	const getDefaultQueryDate = (activeFromString?: string): DateRangePickerValue => {
		var momentFromDate = moment().subtract(30, "d");
		var fromDate = momentFromDate.toDate();

		var toDate = new Date();

		if (activeFromString) {
			if (momentFromDate.isBefore(activeFromString)) {
				fromDate = new Date(activeFromString);
			}
		}
		return { from: fromDate, to: toDate, selectValue: "t" };
	};

	const getCardIcon = (statId) => {
		switch (statId) {
			case "new_subscribers":
				return UserGroupIcon;
			case "amount_spent":
				return BanknotesIcon;
			case "new_subscribers_graph":
				return ChartBarIcon;
			case "cumulative_subscribers_graph":
				return ChartBarIcon;
			default:
				return CheckIcon;
		}
	};

	const onPickerDateChange = (dateData) => {
		setSelectedMonthData({
			...dateData,
		});

		if (dateData.from === undefined || dateData.to === undefined) {
			return;
		}

		if (dateData) {
			setIsMetricLoading(true);
			networkManager
				.getAffiliateOverviewStats(props.selectedNewsletterId, dateData.from, dateData.to)
				.then((affiliateOverviewResponse) => {
					setOverviewStatResponse(affiliateOverviewResponse);
					setIsMetricLoading(false);
					setIsLoading(false);
				})
				.catch((_error) => {
					setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't load your stats. Please try again later." });
				});
		}
	};

	const renderLoadingSpinner = () => {
		return <Spinner className="loading-spinner spinner-border-sm mt-3 w-[10px]" animation="border" role="status" />;
	};

	const renderMetric = (stat: PUBBReferralOverviewStat) => {
		switch (stat.type) {
			case OverviewStatType.Metric:
				return (
					<Card key={stat.id} className="col-span-1">
						<Flex alignItems="start">
							<div>
								<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
								<div className="truncate mt-4">
									<Title>{stat.title}</Title>
									{isMetricLoading ? renderLoadingSpinner() : <Metric>{stat.value}</Metric>}
								</div>
							</div>
							{stat.status && (
								<div>
									<Badge size="md" color={stat.status_color}>
										{stat.status.toUpperCase()}
									</Badge>
								</div>
							)}
							{stat.hint && (
								<div>
									<Tooltip message={stat.hint}>
										<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
									</Tooltip>
								</div>
							)}
						</Flex>
					</Card>
				);
			case OverviewStatType.Bar:
				return (
					<Card key={stat.id} className="col-span-2">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-col items-start">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<div className="truncate mt-4">
										<Title>{stat.title}</Title>
									</div>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <BarChart data={stat.data_points} index="date" categories={["Cumulative New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</Card>
				);
			case OverviewStatType.Line:
				return (
					<Card key={stat.id} className="col-span-2">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-col items-start">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<div className="truncate mt-4">
										<Title>{stat.title}</Title>
									</div>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <LineChart data={stat.data_points} index="date" categories={["New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</Card>
				);
		}
	};

	const getStripePortalAddress = () => {
		return process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + encodeURI(props.publisherProfile.email);
	}

	return (
		<div>
			{props.loadingProfile || !props.isGrowActive || !props.isGrowAffiliateActive || isLoading ? (
				<LoadingView isLoading={props.loadingProfile || isLoading} />
			) : (
				<div>
					<Toast toastState={toastState} />
					{affiliateDetail && !affiliateDetail.cc_on_file && (
						<div className="w-full border-[1px] bg-unsubscribe-red border-card rounded-[10px] mb-5 p-[20px] font-regular font-primary text-fs-body flex flex-row items-center gap-3">
							<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
							<div><a href={getStripePortalAddress()} target="_blank" className="text-primary-light">Add your card</a> information to prevent any disruptions to your campaign.</div>
						</div>
					)}
					{affiliateDetail && affiliateDetail.active_price && (
						<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px] font-regular font-primary text-fs-body flex flex-row items-center gap-3">
							<Icon icon={CurrencyDollarIcon} color="green" variant="light" size="sm" />
							<div>Your CPA is {affiliateDetail?.active_price}</div>
						</div>
					)}
					<div className="flex flex-col sm:flex-row justify-between items-left sm:items-center pb-3 gap-3 sm:gap-0">
						<div className="font-medium font-primary text-fs-heading">Performance</div>
						<div>
							<DateRangePicker value={selectedMonthData} locale={enGB} weekStartsOn={1} minDate={affiliateDetail?.active_from ? new Date(affiliateDetail?.active_from) : new Date()} maxDate={new Date()} enableClear={false} onValueChange={(dateData) => onPickerDateChange(dateData)} className="max-w-full" />
						</div>
					</div>
					<div className="grid gap-6 grid-cols-2">
						{overviewStatResponse &&
							overviewStatResponse.overview_stats
								.sort((a, b) => a.sort_index - b.sort_index)
								.map((stat) => {
									return renderMetric(stat);
								})}
					</div>
				</div>
			)}
		</div>
	);
};

export default withRouter(GrowAffiliateDashboard);
