import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./MonthPicker.module.scss";

export function MonthPicker(props) {
	const [month, setMonth] = useState(props.selected.month ? props.selected.month - 1 : new Date().getMonth());
	const [year, setYear] = useState(props.selected.year ?? new Date().getFullYear());

	const getCurrentMonthAndYear = () => {
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();
		return [currentMonth, currentYear];
	};

	const setActiveMonthBgColor = (r, color) => {
		r.style.setProperty("--month-active-bg-color", color);
	};

	useEffect(() => {
		const r = document.querySelector(":root");
		if (props.bgColorMonthActive) {
			setActiveMonthBgColor(r, props.bgColorMonthActive);
		}
		if (props.bgColorMonthHover) {
			r.style.setProperty("--month-hover-bg-color", props.bgColorMonthHover);
		}
		if (props.borderRadiusMonth) {
			r.style.setProperty("--month-border-radius", props.borderRadiusMonth);
		}
		if (props.bgColorPicker) {
			r.style.setProperty("--picker-bg-color", props.bgColorPicker);
		}
		if (props.textColor) {
			r.style.setProperty("--text-color", props.textColor);
		}
		if (props.size == "small") {
			r.style.setProperty("--picker-padding", "1rem");
			r.style.setProperty("--year-display-margin-top", "0.5rem");
			r.style.setProperty("--year-display-margin-bottom", "0.5rem");
			r.style.setProperty("--month-select-padding", "0.5rem");
		}
	}, []);

	const changeYear = (year) => {
		setYear(year);
	};

	const getMonthNames = (locale = "en", format = "short") => {
		const formatter = new Intl.DateTimeFormat(locale, {
			month: format,
			timeZone: "UTC",
		});
		const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
			const mm = month < 10 ? `0${month}` : month;
			return new Date(`2023-${mm}-01T00:00:00+00:00`);
		});
		return months.map((date) => formatter.format(date));
	};

	const changeMonth = (month) => {
		setMonth(month);
		props.setIsOpen(false);
		props.onChange({
			month: month + 1,
			year: year,
			monthName: new Date(year, month).toLocaleString(props.lang ? props.lang : "en", {
				month: "long",
			}),
			monthShortName: new Date(year, month).toLocaleString(props.lang ? props.lang : "en", {
				month: "short",
			}),
		});
	};

	const isBelowMaxYear = () => {
		
		if (props.selected.minDate) {
			const [minYear, minMonth] = props.selected.minDate.split('-');
			return year > minYear;
		}

		return false
	}

	const isAboveMinYear = () => {
		return year < getCurrentMonthAndYear()[1];
	}

	const isBelowMaxMonth = (monthIndex) => {
		
		const [currentMonth, currentYear] = getCurrentMonthAndYear();

		if (year == currentYear) {
			return monthIndex <= (currentMonth - 1)
		}
		
		return year <= currentYear;
	}

	const isAboveMinMonth = (monthIndex) => {

		if (props.selected.minDate) {
			const [minYear, minMonth] = props.selected.minDate.split('-');

			if (year == minYear) {
				return monthIndex >= (minMonth - 1);
			}

			return year >= minYear;
		}

		return false

	}

	return (
		<div className={styles.pickerContainer} ref={props.pickerRef}>
			<div className={styles.yearContainer}>
				<button disabled={!(isBelowMaxYear())} aria-label="Previous Year" onClick={(e) => changeYear(year - 1)} style={{opacity: isBelowMaxYear() ? 1.0 : 0.25 }} >
					<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_1211_485)">
							<path d="M9.96094 20.7852C15.4102 20.7852 19.9219 16.2637 19.9219 10.8242C19.9219 5.375 15.4004 0.863281 9.95117 0.863281C4.51172 0.863281 0 5.375 0 10.8242C0 16.2637 4.52148 20.7852 9.96094 20.7852ZM9.96094 19.125C5.35156 19.125 1.66992 15.4336 1.66992 10.8242C1.66992 6.21484 5.3418 2.52344 9.95117 2.52344C14.5605 2.52344 18.2617 6.21484 18.2617 10.8242C18.2617 15.4336 14.5703 19.125 9.96094 19.125Z" fill="white" />
							<path d="M4.94141 10.8145C4.94141 11.0293 5.03906 11.2051 5.21484 11.3809L8.51562 14.6914C8.64258 14.8184 8.81836 14.8965 9.02344 14.8965C9.42383 14.8965 9.72656 14.584 9.72656 14.1836C9.72656 13.9688 9.64844 13.793 9.51172 13.666L8.29102 12.4551L7.10938 11.4688L9.17969 11.5371H14.2676C14.6973 11.5371 15 11.2344 15 10.8145C15 10.3848 14.6875 10.082 14.2676 10.082H9.17969L7.11914 10.1602L8.29102 9.17383L9.51172 7.96289C9.63867 7.82617 9.72656 7.65039 9.72656 7.43555C9.72656 7.03516 9.42383 6.73242 9.02344 6.73242C8.81836 6.73242 8.64258 6.80078 8.51562 6.92773L5.21484 10.2481C5.01953 10.4434 4.94141 10.5996 4.94141 10.8145Z" fill="white" />
						</g>
						<defs>
							<clipPath id="clip0_1211_485">
								<rect width="20.2832" height="19.9316" fill="white" transform="translate(0 0.863281)" />
							</clipPath>
						</defs>
					</svg>
				</button>
				<span aria-description="Year selected" className={styles.bold1}>
					{year}
				</span>
				<button disabled={!(isAboveMinYear())} aria-label="Next Year" onClick={(e) => changeYear(year + 1)} style={{opacity: isAboveMinYear() ? 1.0 : 0.25 }}>
					<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_1211_475)">
							<path d="M9.96094 20.8535C15.4102 20.8535 19.9219 16.332 19.9219 10.8926C19.9219 5.44336 15.4004 0.931641 9.95117 0.931641C4.51172 0.931641 0 5.44336 0 10.8926C0 16.332 4.52148 20.8535 9.96094 20.8535ZM9.96094 19.1933C5.35156 19.1933 1.66992 15.5019 1.66992 10.8926C1.66992 6.2832 5.3418 2.5918 9.95117 2.5918C14.5605 2.5918 18.2617 6.2832 18.2617 10.8926C18.2617 15.5019 14.5703 19.1933 9.96094 19.1933Z" fill="white" />
							<path d="M14.9707 10.8828C14.9707 10.668 14.8926 10.5117 14.6973 10.3164L11.3965 6.99609C11.2695 6.86914 11.0938 6.80078 10.8887 6.80078C10.4883 6.80078 10.1855 7.10352 10.1855 7.50391C10.1855 7.71875 10.2734 7.89453 10.4004 8.03125L11.6211 9.24219L12.8027 10.2285L10.7422 10.1504H5.64453C5.22461 10.1504 4.91211 10.4531 4.91211 10.8828C4.91211 11.3027 5.22461 11.6054 5.64453 11.6054H10.7422L12.8027 11.5371L11.6211 12.5234L10.4004 13.7343C10.2637 13.8613 10.1855 14.0371 10.1855 14.2519C10.1855 14.6523 10.4883 14.9648 10.8887 14.9648C11.0938 14.9648 11.2695 14.8867 11.3965 14.7597L14.6973 11.4492C14.8828 11.2734 14.9707 11.0976 14.9707 10.8828Z" fill="white" />
						</g>
						<defs>
							<clipPath id="clip0_1211_475">
								<rect width="20.2832" height="19.9316" fill="white" transform="translate(0 0.931641)" />
							</clipPath>
						</defs>
					</svg>
				</button>
			</div>
			<div className={styles.monthsContainer}>
				{getMonthNames(props.lang).map((monthName, index) => {
					return (
						<button disabled={!(isBelowMaxMonth(index) && isAboveMinMonth(index))} key={index} className={`${styles.month} ${index == month && (props.selected.year ?? new Date().getFullYear()) == year ? styles.active : null}`} style={{opacity: isBelowMaxMonth(index) && isAboveMinMonth(index) ? 1.0 : 0.25 }} onClick={(e) => changeMonth(index)}>
							{monthName}
						</button>
					);
				})}
			</div>
		</div>
	);
}
