import mixpanel from "mixpanel-browser";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_STAGE === 'local'}); 

export function recordEvent(name, properties) {
	if (properties?.length > 0) {
		mixpanel.track(name, properties);
	} else {
		mixpanel.track(name, properties);
	}
}

export function analyticsCompletedLogin(profile) {
	createMixpanelProfile(profile);
	recordEvent(kAnalyticsConstants.Login.completed);
}

function createMixpanelProfile(profile) {
	mixpanel.alias(profile.id, mixpanel.get_distinct_id());
	mixpanel.identify(mixpanel.get_distinct_id());
    refreshSuperProperties(profile);
}

export async function refreshSuperProperties(profile) {
	let firstName = profile.first_name;
	let lastName = profile.last_name;
	let email = profile.email;
	let newsletters = profile.newsletters;
	let newsletterNames = newsletters.map((x) => x.name);
	let newsletterIds = newsletters.map((x) => x.id);
	let userId = profile.id;

	if (firstName == null || lastName == null || email == null) {
		return;
	}

	mixpanel.people.set({ $first_name: firstName, $last_name: lastName, $email: email, user_id: userId, newsletters: newsletterNames, newsletter_ids: newsletterIds });

	mixpanel.register({ user_id: userId, newsletters: newsletterNames, newsletter_ids: newsletterIds });
}
