import { FC, useState, useEffect } from "react";
import styles from "./AccountCreation.module.scss";
import { ReactComponent as MecoPublishersLogo } from "../assets/images/meco_publishers_logo.svg";
import { ReactComponent as LoadingSpinner } from "../assets/images/loading_spinner.svg";
import ParticlesBackground from "../utils/ParticlesBackground";
import Toast, { ToastState } from "../utils/DesignSystem/Toast";
import * as networkManager from "../networking/NetworkManager";
import { kStringConstants } from "../constants/StringConstants";
import { kErrorConstants } from "../constants/ErrorConstants";
import { History, LocationState } from "history";
import * as userManager from "../managers/UserManager";

interface AccountCreationProps {
	history: History<LocationState>;
}

const AccountCreationView: FC<AccountCreationProps> = (props) => {
	const [isLoading, setLoading] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [signUpUserModel, setSignUpUserModel] = useState<Record<string, string>>({
		email: "",
		first_name: "",
		last_name: "",
		newsletter_name: "",
		newsletter_url: "",
	});

	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});

	useEffect(() => {
		userManager.logOutUser();
	}, []);

	const inputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const fieldValue: string = e.currentTarget.value;
		const fieldId: string = e.currentTarget.id;

		const updatedModel = {
			...signUpUserModel,
			[fieldId]: fieldValue,
		};

		const isEmailValid = checkEmail(updatedModel.email);
		const isNewsletterUrlValid = checkNewsletterUrl(updatedModel.newsletter_url);

		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setSignUpUserModel(updatedModel);
		setIsFormValid(isEmailValid && isNewsletterUrlValid && areAllFieldsFilled);
	};

	const goToMagicLink = (emailAddress) => {
		props.history.push("/magic-link", { email: emailAddress });
	};

	const continueButtonOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!isFormValid) {
			return;
		}

		setLoading(true);

		networkManager
			.signUpUser(signUpUserModel)
			.then((_response) => {
				goToMagicLink(signUpUserModel.email);
			})
			.catch((error) => {
				setLoading(false);
				generateSignUpError(error);
			});
	};

	const generateSignUpError = (error) => {
		var title = kStringConstants.Common.errorAlertTitle;
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.signUp.existingAccount) {
			title = "Account already exists!";
			message = "To access this account please log in.";
		}

		setToastState({ status: "error", title: title, message: message });
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	function checkNewsletterUrl(newsletterUrl: string) {
		const regex = /\S/;
		return regex.test(newsletterUrl) && newsletterUrl != "";
	}

	return (
		<div>
			<Toast toastState={toastState} />
			<div className="bg-black">
				<div className={styles.gradient_overlay}></div>
				<ParticlesBackground />
				<div className={styles.green_gradient}></div>
				<div className={styles.red_gradient}></div>
				<div className="flex flex-col gap-10 items-center justify-center p-3 mt-[64px]">
					<MecoPublishersLogo className={styles.meco_logo} />
					<div className="meco_container max-w-[500px] mb-5 py-[40px] px-[20px] sm:p-[60px]">
						<div className="flex flex-col gap-2">
							<div className="text-white font-black font-primary text-fs-heading-extra-large">Become a partner.</div>
							<div className="text-white/50 font-regular font-primary text-fs-heading-small">Sign up your newsletter to start earning and growing with Meco.</div>
							<form className="flex flex-col mt-4 gap-4" onSubmit={continueButtonOnClick}>
								<div className="flex flex-col gap-3 flex-grow">
									<div className="flex flex-col sm:flex-row gap-3 flex-grow">
										<input type="text" disabled={isLoading} id="first_name" value={signUpUserModel.first_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="First Name" />
										<input type="text" disabled={isLoading} id="last_name" value={signUpUserModel.last_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Last Name" />
									</div>
									<input type="email" disabled={isLoading} id="email" value={signUpUserModel.email} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Email address" />
									<input type="text" disabled={isLoading} id="newsletter_name" value={signUpUserModel.newsletter_name} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Newsletter Name" />
									<input type="text" disabled={isLoading} id="newsletter_url" value={signUpUserModel.newsletter_url} autoFocus={false} onChange={inputOnChange} className={`bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Newsletter URL" />
								</div>
								<div className="flex flex-col gap-3 flex-grow">
									<button type="submit" disabled={!isFormValid || isLoading} className={`cta-button ${isFormValid ? "opacity-100" : "opacity-50"}`}>
										{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <div>Continue</div>}
									</button>
									<div className="text-white/50 font-regular font-primary text-fs-body-extra-small text-center">
										By joining Meco, you consent to our{" "}
										<a href="https://www.meco.app/terms" target="_blank" className="text-white/50 hover:text-success-green transition ease-in-out">
											terms & conditions
										</a>
									</div>
								</div>
							</form>

							<div className="text-white font-regular font-primary text-fs-heading-small mt-5">
								Already have an account?{" "}
								<a href="/login" className="text-success-green hover:text-success-green transition ease-in-out">
									Log In
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountCreationView;
