import React, { Component, useEffect, useState } from "react";
import styles from "./Alerts.module.scss";
import { withRouter } from "react-router-dom";
import { Form, Col, Dropdown, DropdownButton } from "react-bootstrap";
import LoadingView from "../utils/LoadingView";
import * as networkManager from "../networking/NetworkManager";
import * as analyticsManager from "../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";

const Alerts = (props) => {
	const [selectedWeekDay, setSelectedWeekDay] = useState(null);
	const [dailyAlert, setDailyAlert] = useState(false);
	const [instantAlert, setInstantAlert] = useState(false);
	const [readyForBanner, setReadyForBanner] = useState(false);
	const weekDays = ["Off", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

	useEffect(() => {
		console.log(props);
	}, []);

	useEffect(() => {
		setSelectedWeekDay(props.publisherProfile?.alerts?.weekly_summary == undefined ? 0 : props.publisherProfile?.alerts?.weekly_summary);
		setInstantAlert(props.publisherProfile?.alerts?.instant == undefined ? false : props.publisherProfile?.alerts?.instant);
		setDailyAlert(props.publisherProfile?.alerts?.daily_summary == undefined ? false : props.publisherProfile?.alerts?.daily_summary);
		if (props.publisherProfile != undefined) {
			setReadyForBanner(true);
		}
	}, [props.publisherProfile]);

	function instantAlertChanged() {
		setInstantAlert(!instantAlert);
		syncAlertsWithBackend(!instantAlert, dailyAlert, selectedWeekDay);
	}

	function dailySummaryChanged() {
		setDailyAlert(!dailyAlert);
		syncAlertsWithBackend(instantAlert, !dailyAlert, selectedWeekDay);
	}

	function weeklySummaryChanged(index) {
		setSelectedWeekDay(index);
		syncAlertsWithBackend(instantAlert, dailyAlert, index);
	}

	function syncAlertsWithBackend(copyInstantAlert, dayAlert, weekAlert) {
		let weeklySummary = weekAlert == 0 ? null : weekAlert;
		let payload = { weekly_summary: weeklySummary, daily_summary: dayAlert, instant: copyInstantAlert };
		networkManager.postAlerts(payload);
		analyticsManager.recordEvent(kAnalyticsConstants.Alerts.changed, {"weekly_summary_status" : weeklySummary, "daily_summary_status": dayAlert, "instant_status" : copyInstantAlert});
	}

	return (
		<div>
			{props.loadingProfile || weekDays == undefined || selectedWeekDay == null ? (
				<LoadingView isLoading={props.loadingProfile} />
			) : (
				<div>
					{selectedWeekDay == 0 && dailyAlert == false && instantAlert == false && readyForBanner && <div className={styles.off_warning}>[Important] We highly recommend at least 1 active alert so that we can inform you of any new subscribers to your newsletter. Without alerts you may miss out on new readers.</div>}
					<div className={styles.notification_banner}>
						<div className="row align-items-center pb-5">
							<Col xs={7} md={5}>
								Instant alerts for new subscribers:
								<br />
								<div className={styles.alert_note}>Get emailed instantly for each of your subscriber for your newsletter.</div>
							</Col>
							<Col xs={5} md={7} style={{ textAlign: "right" }}>
								<Form>
									<Form.Check type="switch" id="custom-switch" checked={instantAlert} onChange={() => instantAlertChanged()} />
								</Form>
							</Col>
						</div>
						<div className="row align-items-center pb-5">
							<Col xs={7} md={5}>
								Daily summary of new subscribers:
								<br />
								<div className={styles.alert_note}>A daily email (sent at 7am local time) to inform you of the new subscribers you've had for your newsletter from the previous day. You won’t get an email if there were no new subscribers.</div>
							</Col>
							<Col xs={5} md={7} style={{ textAlign: "right" }}>
								<Form>
									<Form.Check type="switch" id="custom-switch" checked={dailyAlert} onChange={() => dailySummaryChanged()} />
								</Form>
							</Col>
						</div>
						<div className="row align-items-center">
							<Col xs={7} md={5}>
								Weekly summary of new subscribers:
								<br />
								<div className={styles.alert_note}>A weekly email (sent at 7am local time on a day of your choosing) informing you of any new subscribers for your newsletter from the previous week. You won’t get an email if there were no new subscribers.</div>
							</Col>
							<Col xs={5} md={7} style={{ textAlign: "right" }}>
								<DropdownButton key="weekly" variant={selectedWeekDay == 0 ? "secondary" : "success"} title={weekDays[selectedWeekDay]}>
									{weekDays.map(function (name, index) {
										return (
											<Dropdown.Item eventKey={index} key={index} onClick={() => weeklySummaryChanged(index)}>
												{name}
											</Dropdown.Item>
										);
									})}
								</DropdownButton>
							</Col>
						</div>
					</div>
					{props.publisherProfile && (
						<div className={styles.contact_details}>
							Alerts are currently being sent to: <div className={styles.green}>{props.publisherProfile.email}</div>. To change this address <a href="mailto:publishers@meco.app"> please contact us</a>.
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default withRouter(Alerts);
