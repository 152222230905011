import { FC, useState, useRef, useEffect } from "react";
import styles from "./AccountCreation.module.scss";
import { ReactComponent as MecoPublishersLogo } from "../assets/images/meco_publishers_logo.svg";
import ParticlesBackground from "../utils/ParticlesBackground";
import Toast, { ToastState } from "../utils/DesignSystem/Toast";
import * as networkManager from "../networking/NetworkManager";
import { kStringConstants } from "../constants/StringConstants";
import { kErrorConstants } from "../constants/ErrorConstants";
import { useLocation } from "react-router-dom";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import emailAnimation from "../assets/images/email_animation.json";
import LoadingView from "../utils/LoadingView";
import { kLocalStorageKeys } from "../constants/kLocalStorageKeys";
import * as analyticsManager from "../managers/AnalyticsManager";
import { NLProfile } from "../models/Models";

export interface MagicLinkObject {
	token: string;
	is_profile_setup: string;
	email_address: string;
}

interface MagicLinkViewState {
	magicLinkObject: MagicLinkObject | null;
	email: string;
}

const MagicLinkView = (props) => {
	const [isLoading, setLoading] = useState(false);
	const [isAnimationComplete, setAnimationComplete] = useState(false);
	const [lastResendAttempt, setLastResendAttempt] = useState<Date | null>(null);
	const playerRef = useRef<LottieRefCurrentProps>(null);
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});

	const magicLinkViewState: MagicLinkViewState | null = useLocation<MagicLinkViewState>().state;
	const magicLinkObject: MagicLinkObject | null = magicLinkViewState?.magicLinkObject ?? null;
	const emailAddress: string | null = magicLinkViewState?.email ?? null;

	useEffect(() => {
		if (!emailAddress && !magicLinkObject) {
			props.history.push("/login");
			return;
		}

		if (magicLinkObject) {
			freezeAnimation();
			setAnimationComplete(true);
			signInWithBackend(magicLinkObject);
			return;
		}
	}, []);

	const resendOnClick = (e) => {
		e.preventDefault();

		if (!isEligibleForResend()) {
			setToastState({ status: "error", title: "Please wait before retrying", message: "It can take a minute or two for the magic link email to arrive. Remember to check your spam folder." });
			return;
		}

		networkManager
			.logInUser(emailAddress)
			.then((response) => {
				setLastResendAttempt(new Date());
				setToastState({ status: "success", title: "Magic link resent", message: "Please allow a moment for the email to arrive and make sure to check your spam folder." });
			})
			.catch((error) => {
				setToastState({ status: "error", title: kStringConstants.Common.errorAlertTitle, message: kStringConstants.Common.errorAlertMessage });
			});
	};

	function isEligibleForResend(): boolean {
		if (!lastResendAttempt) {
			return true;
		}

		const elapsed: number = (new Date().getTime() - lastResendAttempt.getTime()) / 1000;
		const duration: number = Math.floor(elapsed);

		return duration > 60;
	}

	const generateError = (error) => {
		var title = kStringConstants.Common.errorAlertTitle;
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.signUp.existingAccount) {
			title = "Account already exists!";
			message = "To access this account please log in.";
		}

		setToastState({ status: "error", title: title, message: message });
	};

	const freezeAnimation = () => {
		if (playerRef.current) {
			var frames = playerRef.current.getDuration(true);
			if (frames !== null && frames !== undefined) {
				frames = frames - 1;
				playerRef.current.goToAndStop(frames, true);
			}
		}
	};

	const signInWithBackend = (magicLinkObject: MagicLinkObject) => {
		setLoading(true);

		validateUserWithMagicLinkToken(magicLinkObject.token)
			.then(() => {
				return getUserProfile()
			})
			.then((profile) => {
				didCompleteSignIn(profile);
			})
			.catch((error) => {
				props.history.push("/login", { magicLinkObject: magicLinkObject });
				return
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const validateUserWithMagicLinkToken = (magicLinkToken: string): Promise<void> => {
		return new Promise((resolve, reject) => {
			networkManager
				.validateMagicLinkToken(magicLinkToken)
				.then((userToken) => {
					localStorage.setItem(kLocalStorageKeys.User.token, userToken);
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const getUserProfile = (): Promise<NLProfile> => {
		return new Promise(function (resolve, reject) {
			networkManager.getProfile().then((profile) => {
				resolve(profile);
			}).catch((error) => {
				reject(error);
			})
		});
	};

	const didCompleteSignIn = (userProfile: NLProfile) => {
		analyticsManager.analyticsCompletedLogin(userProfile);
		props.history.push("/");
	};

	return (
		<div>
			<LoadingView isLoading={isLoading} loadingMessage="Signing you in" />
			<Toast toastState={toastState} />
			<div className="bg-black h-screen w-screen">
				<div className={styles.gradient_overlay}></div>
				<ParticlesBackground />
				<div className={styles.green_gradient}></div>
				<div className={styles.red_gradient}></div>
				<div className="flex flex-col gap-10 items-center justify-center h-screen p-3">
					<MecoPublishersLogo className={styles.meco_logo} />
					<div className="meco_container max-w-[500px] mb-5 py-[40px] px-[20px] sm:p-[60px]">
						<div className="flex flex-col gap-2">
							<div className={`text-white font-black font-primary text-fs-heading-extra-large ${isAnimationComplete ? "transition-opacity ease-in duration-250 opacity-100" : "opacity-0"}`}>Check your email!</div>
							<div className={`text-white/50 font-regular font-primary text-fs-heading-small ${isAnimationComplete ? "transition-opacity ease-in duration-250 opacity-100" : "opacity-0"}`}>Confirm your email address by tapping the link in the email we just sent you.</div>
							<Lottie className="max-h-[280px]" animationData={emailAnimation} lottieRef={playerRef} loop={false} onComplete={() => setAnimationComplete(true)} />

							<div className={`text-white font-regular font-primary text-fs-body leading-loose"  ${isAnimationComplete ? "transition-opacity ease-in delay-500 duration-250 opacity-100" : "opacity-0"}`}>
								Didn’t receive an email?
								<br />
								Check your spam folder,{" "}
								<a href="" onClick={resendOnClick} className="text-success-green hover:text-success-green transition ease-in-out">
									resend email
								</a>{" "}
								or{" "}
								<a href="mailto:team@meco.app" className="text-success-green hover:text-success-green transition ease-in-out">
									{" "}
									contact us
								</a>
								.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MagicLinkView;
