import { ReactComponent as ExitIcon } from "../../assets/images/exit_icon.svg";
import { useHistory } from "react-router-dom";
import { FC, useState, useRef, useEffect } from "react";
import LoadingView from "../../utils/LoadingView";
import * as networkManager from "../../networking/NetworkManager";

const EarnPaymentSettingsModal = (props) => {
	const [isVisible, setVisible] = useState(false);
	const [isEmailValid, setEmailValid] = useState<boolean>(false);
	const [emailString, setEmailString] = useState<string | undefined>(undefined);
	const [isLoading, setLoading] = useState<boolean>(false);
    const inputRef = useRef(null);

	const history = useHistory();

	useEffect(() => {
		if (props.isShow) {
			document.body.style.overflow = "hidden";
            // @ts-ignore
            inputRef.current.value = props.paypalAccount ? props.paypalAccount : "";
			setVisible(true);
		}

		if (!props.isShow) {
			setTimeout(() => {
				document.body.style.overflow = "unset";
				setVisible(false);
				const queryParams = new URLSearchParams(history.location.search);
				queryParams.delete("paymentSettings");
				history.replace({
					search: queryParams.toString(),
				});
			}, 451);
		}
	}, [props.isShow]);

    useEffect(() => {
        setEmailString(props.paypalAccount);
    }, [props.paypalAccount])

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	const inputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        const emailValue = e.currentTarget.value;
        setEmailValid(checkEmail(emailValue));
        setEmailString(emailValue);
	};

    const saveButtonOnClick = (e) => {
        e.preventDefault();
        setLoading(true);
        
        if (!isEmailValid || !emailString) {
            setLoading(false);
            return
        }

        networkManager.postPaypalEmail(props.selectedNewsletterId, emailString).finally(() => {
            setLoading(false);
            props.setIsShow(false);
            props.setPaypalAccount(emailString);
        })
    }

	return (
		<div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center transition-all duration-450 ease-in-out z-50 ${props.isShow ? "opacity-1" : "opacity-0"} ${isVisible ? "visible overflow-hidden" : "hidden"}`}>
            <LoadingView isLoading={isLoading} />
			<div className="max-h-[90vh] bg-white rounded-[10px] shadow-md p-6 sm:p-8 w-[90%] max-w-[700px] flex flex-col gap-4 overflow-scroll">
				<div className="flex flex-row gap-3 justify-between items-center">
					<div className="text-secondary-dark font-bold font-primary text-fs-heading">Payment Settings</div>
					<button onClick={() => props.setIsShow(false)}>
						<ExitIcon className="h-[26px] opacity-50" />
					</button>
				</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">Successful referrals are paid using PayPal. Please add the email address associated with your PayPal account to be able to receive payments from Meco.</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">
					If you have any questions please email{" "}
					<a href="mailto:team@meco.app" target="_blank" className="color: text-success-green hover:text-success-green font-bold">
						team@meco.app
					</a>
					.
				</div>
				<form className="flex flex-col gap-4" onSubmit={(e) => saveButtonOnClick(e)}>
					<input type="email" disabled={false} id="email" ref={inputRef} autoFocus={true} onChange={inputOnChange} className={`bg-black/80 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Your PayPal email address" />
					<div className="h-[1px] w-full bg-secondary-dark/20"></div>
					<div className="flex flex-row justify-end gap-3">
						<button  type="submit" disabled={!isEmailValid} className={`text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap ${isEmailValid ? "opacity-100" : "opacity-50"}`}>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EarnPaymentSettingsModal;
