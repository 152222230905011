import React, { Fragment, forwardRef, useImperativeHandle, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

export default forwardRef((props, ref) => {
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const isManualSet = useRef(false);

	// expose AG Grid Filter Lifecycle callbacks
	useImperativeHandle(ref, () => {
		return {
			onParentModelChanged(parentModel) {
				// When the filter is empty we will receive a null value here
				if (!isManualSet.current) {
					if (!parentModel) {
						return
					} else {
          
            var dateFrom = null;
            var dateTo = null;
  
            if (parentModel.dateFrom) {
              let dateFromString = moment(parentModel.dateFrom).format("yyyy-MM-DD");
              dateFrom = moment(dateFromString, 'yyyy-MM-DD').toDate();
            }
  
            if (parentModel.dateTo) {
              let selectedDate = new Date(parentModel.dateTo)
              let dateToString = moment(selectedDate).format("yyyy-MM-DD");
              dateTo = moment(dateToString, 'yyyy-MM-DD').toDate();
            }
              
						setDateRange([dateFrom, dateTo]);
					}
				} else {
					isManualSet.current = false;
				}
			},
		};
	});

	const onDateChange = (input) => {
		if (input[0] != null && input[1] == null) {
			return;
		}

		if (input[0] == null && input[1] == null) {
			// Remove the filter
			props.parentFilterInstance((instance) => {
				instance.setModelIntoUi({
					type: "inRange",
					dateFrom: null,
					dateTo: null,
				});
				instance.onUiChanged(true);
			});
			return;
		}

		props.parentFilterInstance((instance) => {
			instance.setModelIntoUi({
				type: "inRange",
				dateFrom: moment(input[0]).format("YYYY-MM-DD"),
				dateTo: moment(input[1]).format("YYYY-MM-DD"),
			});
			instance.onUiChanged(true);
		});
	};

	const style = {
		zIndex: 9999,
		color: props.color,
		width: "100%",
	};

	return (
		<Fragment>
			<DatePicker
				portalId="root"
				selectsRange={true}
				startDate={startDate}
				endDate={endDate}
				placeholderText={"Filter by date"}
				onChange={(update) => {
					isManualSet.current = true;
					setDateRange(update);
					onDateChange(update);

				}}
				isClearable={true}
				style={style}
			/>
		</Fragment>
	);
});
