import { kLocalStorageKeys } from "../constants/kLocalStorageKeys";

export const Production_Base_URL = "https://www.meco.app"
export const Stage_Base_URL = "https://stage.meco.app"

export function baseURL() {
    if (process.env.REACT_APP_STAGE === 'production') {
        return Production_Base_URL
    } else {
        return Stage_Base_URL
    }
}

export const Endpoints = {
    signUpUser: '/api/publisher/sign-up',
    logInUser: '/api/publisher/log-in',
    validateMagicLink: '/api/auth/magic-link',
    tempAuthToken: '/api/publisher/auth',
    subscriberList: '/api/publisher/subscriber_list',
    profile: '/api/publisher/profile',
    csvLastImport: '/api/publisher/csv_last_import',
    alerts: '/api/publisher/alerts',
    integrations: '/api/publisher/integrations',
    getReferralLink: '/api/publisher/referral/get/',
    createReferralLink: '/api/publisher/referral/create/',
    getReferralOverviewStats: '/api/publisher/referral/overview-stats/',
    getReferralHistory: '/api/publisher/referral/history/',
    postPaypalEmail: '/api/publisher/referral/paypal-account/',
    getAffiliateDetail: '/api/publisher/affiliate/get/',
    getAffiliateOverviewStats: '/api/publisher/affiliate/overview-stats/',
}

export function getUserAuthToken() {
    return localStorage.getItem(kLocalStorageKeys.User.token);
}