import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import EarnPaymentSettingsModal from "./EarnPaymentSettingsModal";

function EarnLayout({ children, ...layoutProps }) {
	const history = useHistory();
	const [isPaymentSettingsVisible, setPaymentSettingsVisibility] = useState(layoutProps.paymentSettings);
	const [selectedNewsletterId, setSelectedNewsletter] = useState(layoutProps.selectedNewsletter);

	useEffect(() => {
		const isEarnActive = layoutProps.isEarnActive;
		if (isEarnActive == false) {
			history.push("/earn/join");
			return
		}

	}, [layoutProps.isEarnActive]);

	useEffect(() => {
		setPaymentSettingsVisibility(layoutProps.paymentSettings);
	}, [layoutProps.paymentSettings]);

	return (
		<div>
			<EarnPaymentSettingsModal isShow={isPaymentSettingsVisible} setIsShow={(showStatus) => setPaymentSettingsVisibility(showStatus)}  {...layoutProps} />
			<div className="container">{React.cloneElement(children, { ...layoutProps })}</div>
		</div>
	);
}

export default EarnLayout;
