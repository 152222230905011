export const kAnalyticsConstants = {
    App: {
        sessionStarted: "MfP - Session Started",
        newsletterSwitched: "MfP - Newsletter Switched",
    },
    Login: {
        presented:  "LGN - Presented",
        sentMagicLink:  "LGN - Sent Magic Link",
        magicLinkError:  "LGN - Magic Link Error",
        completed:  "LGN - Completed",
    },
    Subscribers: {
        exported:  "SBC - All Exported",
        newSubscriberExported:  "SBC - New Subscriber Exported",
        selectionExported:  "SBC - Selection Exported",
    },
    Alerts: {
        changed:  "ALT - Alerts Changed",
    },
    Integrations: {
        added: "INT - Added",
        addedError: "INT - Add Error",
        removed: "INT - Removed",
        removedError: "INT - Remove Error",
    },
    Earn: {
        getLinkClicked: "MfP - Earn Get Link Clicked",
        referralProgramJoined: "MfP - Referral Program Joined",
        referralProgramJoinError: "MfP - Referral Program Join Error",
        assetsViewed: "MfP - Earn Assets Viewed",
    },
}