import { Form, Col, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./Integrations.module.scss";
import React, { useState, useRef, useEffect } from "react";
import AlertView from "../utils/AlertView";

export const SetupWizardModal = (props) => {
	const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
	const [isFormValid, setFormValid] = useState(false);
	const [showDifferentIntegrationAlert, setDifferentIntegrationAlert] = useState(false);
	const [showDeleteIntegrationAlert, setDeleteIntegrationAlert] = useState(false);
	const formRef = useRef(null);

	useEffect(() => {
		
		if (!props.show) {
			return;
		}

		if (props.integrations.newsletter_integration.integration_id) {
			setSelectedIntegrationId(props.integrations.newsletter_integration.integration_id);
		} else {
			setSelectedIntegrationId(null);
		}

		setFormValid(false);

	}, [props.show]);

	function getSelectedIntegration() {
		return props.integrations.integrations.filter((x) => x.id == selectedIntegrationId)[0];
	}

	function integrationChanged(index) {
		setSelectedIntegrationId(index);
	}

	function getStepTitle() {
		let selectedIntegration = getSelectedIntegration();
		let integrationType = selectedIntegration.type;
		let fields = selectedIntegration.fields;
		var addYour = selectedIntegration.name == "Zapier" ? "Copy API key to Zapier" :  "Add your details";
		return addYour
	}

	function generateForms() {
		let selectedIntegration = getSelectedIntegration();
		let fields = selectedIntegration.fields;
		let isExistingIntegration = selectedIntegration.id == props.integrations.newsletter_integration.integration_id;
		var formsToBeAdded = [];

		if (selectedIntegration.name == "Zapier") {
			formsToBeAdded.push(
				<Form.Group key={selectedIntegration.id} className="mb-3">
					<Form.Label className={styles.field_title}>API key</Form.Label>
					<Form.Control type="input" id={selectedIntegration.id} defaultValue={props.integrations.zapier_key} disabled/>
				</Form.Group>
			);
		} else {
			for (let field of fields) {
				var existingValue = null;
				if (isExistingIntegration) {
					let userIntegrationFields = props.integrations.newsletter_integration.fields;
					let existingUserField = Object.keys(userIntegrationFields).filter((x) => x == field)[0];
					existingValue = userIntegrationFields[existingUserField];
				}
				formsToBeAdded.push(
					<Form.Group key={selectedIntegration.id + "_" + field} className="mb-3">
						<Form.Label className={styles.field_title}>{field}</Form.Label>
						<Form.Control type="input" id={selectedIntegration.id + "_" + field} defaultValue={existingValue == null ? "" : existingValue} />
					</Form.Group>
				);	
			}
		}

		return formsToBeAdded;
	}

	function getFormValues() {
		let formElements = formRef.current.elements;
		var formValues = {};

		for (let i = 0; i < formElements.length; i++) {
			let element = formElements[i];
			let inputArray = element.id.split("_");
			let inputKey = inputArray[inputArray.length - 1];
			if (inputKey) {
				element.value = element.value.trim()
				formValues[inputKey] = element.value;
			}
		}

		return formValues;
	}

	function formValueChanged() {
		let formValues = getFormValues();
		validateForm(formValues);
	}

	function validateForm(formValues) {
		if (JSON.stringify(props.integrations.newsletter_integration.fields) === JSON.stringify(formValues)) {
			setFormValid(false);
			return;
		}
		setFormValid(Object.values(formValues).every((input) => !input.match(/^\s*$/g)));
	}

	function submitIntegration(isForce) {
		let selectedIntegration = getSelectedIntegration();
		
		if (!isForce && props.integrations.newsletter_integration.integration_id != undefined && selectedIntegrationId != props.integrations.newsletter_integration.integration_id) {
			//If integrations change
			setDifferentIntegrationAlert(true);
			return;
		}

		if (selectedIntegration.name == "Zapier") {
			if (showDifferentIntegrationAlert) {
				deleteIntegration(true);
				setDifferentIntegrationAlert(false);
			}
			window.open('https://zapier.com/developer/public-invite/178736/a98242c90664a40fe6844e5ac2e6ce69/', '_blank');
			return
		}

		if (!isFormValid) {
			return;
		}

		var formValues = getFormValues();

		formValues["id"] = selectedIntegrationId;

		if (!isForce && props.integrations.newsletter_integration.integration_id != undefined && selectedIntegrationId != props.integrations.newsletter_integration.integration_id) {
			//If integrations change
			setDifferentIntegrationAlert(true);
			return;
		}

		setDifferentIntegrationAlert(false);
		props.onSubmitIntegration(formValues);
	}

	function deleteIntegration(deleteQuietly) {
		setDeleteIntegrationAlert(false);
		props.onDeleteIntegration(deleteQuietly);
	}

	function renderDifferentIntegrationAlert() {
		let previousIntegration = props.integrations.integrations.filter((x) => x.id == props.integrations.newsletter_integration.integration_id)[0]?.name;
		let newIntegration = props.integrations.integrations.filter((x) => x.id == selectedIntegrationId)[0]?.name;

		return <AlertView showModal={showDifferentIntegrationAlert} setShowModal={() => setDifferentIntegrationAlert(false)} alertTitle="You're changing your integration" alertDescription={"We will replace your current " + previousIntegration + " integration with " + newIntegration + ". Would you like to continue?"} alertButtonTitle="Continue" dismissButtonTitle="Cancel" alertButtonAction={() => submitIntegration(true)} />;
	}

	function renderDeletionAlert() {
		let previousIntegration = props.integrations.integrations.filter((x) => x.id == props.integrations.newsletter_integration.integration_id)[0]?.name;

		return <AlertView showModal={showDeleteIntegrationAlert} setShowModal={() => setDeleteIntegrationAlert(false)} alertTitle="Are you sure?" alertDescription={"When you remove the " + previousIntegration + " integration, new Meco subscribers won't be automatically added to your newsletter. Would you like to continue?"} alertButtonTitle="Delete" dismissButtonTitle="Cancel" alertButtonAction={() => deleteIntegration()} />;
	}

	function returnCta() {
		let selectedIntegration = getSelectedIntegration();
		let integrationType = selectedIntegration.type;

		if (selectedIntegration.name == "Zapier") {
			return (
				<div className={`${styles.button} mt-3`} onClick={() => submitIntegration()}>
					Connect Zapier
				</div>
			);
		} else {
			return (
				<div className={`${styles.button} ${isFormValid ? null : styles.disabled} mt-3`} onClick={() => submitIntegration()}>
					Save
				</div>
			);
		}

	}

	return (
		<div>
			{renderDifferentIntegrationAlert()}
			{renderDeletionAlert()}
			<Modal show={props.show} onHide={props.setShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title className={styles.modal_title}>Set up integration</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row align-items-center pb-2">
						<Col xs={2} sm={1}>
							<div className={styles.number_circle}>1</div>
						</Col>
						<Col xs={10} sm={11} style={{ textAlign: "left" }}>
							<DropdownButton key="integrations_list" variant={selectedIntegrationId != null ? "success" : "outline-primary"} title={selectedIntegrationId != null ? getSelectedIntegration().name : "Select integration"} className={styles.success_button}>
								{props.integrations.integrations
									.sort((a, b) => a.sort_index - b.sort_index)
									.map(function (integration, index) {
										return (
											<Dropdown.Item eventKey={index} key={index} onClick={() => integrationChanged(integration.id)}>
												{integration.name}
											</Dropdown.Item>
										);
									})}
							</DropdownButton>
						</Col>
					</div>
					{selectedIntegrationId && (
						<div>
							<div className="row align-items-center pt-3">
								<Col xs={2} sm={1}>
									<div className={styles.number_circle}>2</div>
								</Col>
								<Col xs={10} sm={11}>
									<div className={styles.step_name}>{getStepTitle()}</div>
								</Col>
							</div>
							<div className="row align-items-center pt-1">
								<Col md={12}>
									<div dangerouslySetInnerHTML={{ __html: getSelectedIntegration().notice_html }}></div>
								</Col>
								<Form ref={formRef} onChange={formValueChanged}>
									{generateForms()}
									{returnCta()}
									{props.integrations.newsletter_integration.integration_id == selectedIntegrationId && (
										<div className={`${styles.button} ${styles.delete} mt-3`} onClick={() => setDeleteIntegrationAlert(true)}>
											Remove integration
										</div>
									)}
								</Form>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default SetupWizardModal;
