import { FC, useState, useEffect } from "react";
import styles from "./AccountCreation.module.scss";
import { ReactComponent as MecoPublishersLogo } from "../assets/images/meco_publishers_logo.svg";
import { ReactComponent as RightArrow } from "../assets/images/right_arrow.svg";
import { ReactComponent as LoadingSpinner } from "../assets/images/loading_spinner.svg";
import ParticlesBackground from "../utils/ParticlesBackground";
import Toast, { ToastState } from "../utils/DesignSystem/Toast";
import * as networkManager from "../networking/NetworkManager";
import { kStringConstants } from "../constants/StringConstants";
import { kErrorConstants } from "../constants/ErrorConstants";
import { History, LocationState } from "history";
import { useLocation } from "react-router-dom";
import { MagicLinkObject } from "./MagicLinkView";
import * as userManager from "../managers/UserManager";

interface LoginProps {
	history: History<LocationState>;
}

interface MagicLinkState {
	magicLinkObject: MagicLinkObject | null;
}

interface SessionErrorState {
	sessionErrorMessage: string | null;
}

const LoginView: FC<LoginProps> = (props) => {
	const [isValidEmail, setValidEmail] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [emailAddress, setEmailAddress] = useState<string | null>(null);
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});

	const magicLinkObject: MagicLinkObject | null = useLocation<MagicLinkState>().state?.magicLinkObject;
	const sessionErrorObject: string | null = useLocation<SessionErrorState>().state?.sessionErrorMessage;

	useEffect(() => {
		userManager.logOutUser();

		if (magicLinkObject) {
			const errorToastState: ToastState = {
				status: "error",
				title: "Something went wrong",
				message: "Please retry with a new magic link or contact us at team@meco.app if the issue persists.",
			};
			setToastState(errorToastState);
			setEmailAddress(magicLinkObject.email_address);
			validateEmailAddress(magicLinkObject.email_address);
			return
		}

		if (sessionErrorObject) {
			const errorToastState: ToastState = {
				status: "error",
				title: "Something went wrong",
				message: sessionErrorObject,
			};
			setToastState(errorToastState);
			return
		}

	}, []);

	const emailInputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		const emailValue: string = e.currentTarget.value;
		setEmailAddress(emailValue);
		validateEmailAddress(emailValue);
	};

	const validateEmailAddress = (emailAddressString) => {
		const isValid = checkEmail(emailAddressString);
		setValidEmail(isValid);
	};

	const goToMagicLink = (emailAddress) => {
		props.history.push("/magic-link", { email: emailAddress });
	};

	const continueButtonOnClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (!emailAddress || !isValidEmail) {
			return;
		}

		setLoading(true);

		networkManager
			.logInUser(emailAddress)
			.then((response) => {
				goToMagicLink(emailAddress);
			})
			.catch((error) => {
				setLoading(false);
				generateLogInError(error);
			});
	};

	const generateLogInError = (error) => {
		var message = kStringConstants.Common.errorAlertMessage;

		if (error == kErrorConstants.logIn.nonExistantAccount) {
			message = "We couldn’t find an account with this email address.";
		}

		setToastState({ status: "error", title: null, message: message });
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	const determineTitleText = () => {
		let textContent;

		if (magicLinkObject && !magicLinkObject.is_profile_setup) {
			textContent = "Resend magic link";
		} else {
			textContent = "Hello, again.";
		}

		return <div>{textContent}</div>;
	};

	const determineDescriptionText = () => {
		let textContent;

		if (magicLinkObject && !magicLinkObject.is_profile_setup) {
			textContent = "Enter your email below and we’ll email you a new magic link.";
		} else {
			textContent = "Enter the email that you signed up with and we’ll email you a magic link to log in.";
		}

		return <div>{textContent}</div>;
	};

	return (
		<div>
			<Toast toastState={toastState} />
			<div className="bg-black h-screen w-screen">
				<div className={styles.gradient_overlay}></div>
				<ParticlesBackground />
				<div className={styles.green_gradient}></div>
				<div className={styles.red_gradient}></div>
				<div className="flex flex-col gap-10 items-center justify-center h-screen p-3">
					<MecoPublishersLogo className={styles.meco_logo} />
					<div className="meco_container max-w-[500px] mb-5 py-[40px] px-[20px] sm:p-[60px]">
						<div className="flex flex-col gap-2">
							<div className="text-white font-black font-primary text-fs-heading-extra-large">{determineTitleText()}</div>
							<div className="text-white/50 font-regular font-primary text-fs-heading-small">{determineDescriptionText()}</div>
							<form className="flex flex-row  mt-4 gap-3" onSubmit={continueButtonOnClick}>
								<input type="email" value={emailAddress ?? ""} disabled={isLoading} autoFocus={true} onChange={emailInputOnChange} className={`flex-grow bg-black/30 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Enter email address" />
								<button type="submit" disabled={!isValidEmail || isLoading} className={`flex items-center justify-center focus:ring-2 focus:border-white focus:ring-white w-[55px] h-[55px] bg-success-green rounded-full font-regular font-primary text-fs-heading-small ${isValidEmail ? "opacity-100" : "opacity-50"}`}>
									{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <RightArrow className="fill-white h-[20px]" />}
								</button>
							</form>

							<div className="text-white font-regular font-primary text-fs-heading-small mt-5">
								Don't have an account?{" "}
								<a href="/get-started" className="text-success-green hover:text-success-green transition ease-in-out">
									Sign up
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginView;
