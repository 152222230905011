import './App.scss';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute';
import NotFound from './NotFound/NotFound';
import OneClickSubscribe from '../one-click-subscribe/OneClickSubscribe';
import Alerts from '../alerts/Alerts';
import { kLocalStorageKeys } from '../constants/kLocalStorageKeys';
import * as analyticsManager from "../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";
import Integrations from '../integrations/Integrations';
import LoginView from '../onboarding/LoginView';
import AccountCreationView from '../onboarding/AccountCreationView';
import MagicLinkView from '../onboarding/MagicLinkView';
import DeepLinkRoute from './DeepLinkRoute';
import EarnDashboardView from '../earn-dashboard/EarnDashboardView';
import EarnPayouts from '../earn-payouts/EarnPayouts';
import GrowProtectedRoute from './Grow/GrowProtectedRoute';
import GrowJoin from './Grow/GrowJoin';
import EarnProtectedRoute from './Earn/EarnProtectedRoute';
import EarnJoin from './Earn/EarnJoin';
import HomeProtectedRoute from './Home/HomeProtectedRoute';
import GrowAffiliateProtectedRoute from './Grow/GrowAffiliate/GrowAffiliateProtectedRoute';
import GrowAffiliateDashboard from './Grow/GrowAffiliate/Dashboard/GrowAffiliateDashboard';

class App extends Component {

  constructor(props) {
    super(props)

    let sessionStart = sessionStorage.getItem(kLocalStorageKeys.Session.sessionStart);
    
    if (sessionStart) {
      
      let sessionEnd = Date.now();
      let sessionDiff = sessionEnd - sessionStart;
      let hours = Math.floor((sessionDiff / (1000 * 60 * 60)) % 24);

      if (hours >= 6) {
        analyticsManager.recordEvent(kAnalyticsConstants.App.sessionStarted);
        sessionStorage.removeItem(kLocalStorageKeys.Session.sessionStart);
      }

    } else {
      sessionStorage.setItem(kLocalStorageKeys.Session.sessionStart, Date.now());
      analyticsManager.recordEvent(kAnalyticsConstants.App.sessionStarted);
    }
    
  }

  render() {
    return (
      <div>
          <Router>
            <Switch>
                <Route exact path='/login' component={LoginView} />
                <Route exact path='/get-started' component={AccountCreationView} />
                <Route exact path='/magic-link' component={MagicLinkView} />
                <DeepLinkRoute exact path='/auth/:id' component={MagicLinkView} />
                <HomeProtectedRoute exact path="/" />
                <EarnProtectedRoute exact path="/earn" />
                <EarnProtectedRoute exact path="/earn/join" component={EarnJoin} />
                <EarnProtectedRoute exact path="/earn/dashboard" component={EarnDashboardView} />
                <EarnProtectedRoute exact path="/earn/payouts" component={EarnPayouts} />
                <GrowProtectedRoute exact path="/grow" />
                <GrowProtectedRoute exact path="/grow/join" component={GrowJoin} />
                <GrowProtectedRoute exact path="/grow/subscribers" component={OneClickSubscribe} />
                <GrowProtectedRoute exact path="/grow/alerts" component={Alerts} />
                <GrowProtectedRoute exact path="/grow/integrations" component={Integrations} />
                <GrowAffiliateProtectedRoute exact path="/grow/affiliate/dashboard" component={GrowAffiliateDashboard} />
                <Route path="/404" component={NotFound} />
                <Redirect to="/404" />
            </Switch>
          </Router>
      </div>
    )
  }
}

export default App;
