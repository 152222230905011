import React, { useState, useMemo, useEffect, useRef } from "react";
import "./AgGrid.scss";
import styles from "./OneClickSubscribe.module.scss";
import { withRouter } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import * as networkManager from "../networking/NetworkManager";
import moment from "moment-timezone";
import DateFloatingComponent from './DateFloatingComponent.jsx';
import LoadingView from "../utils/LoadingView";
import { Overlay, Tooltip } from "react-bootstrap";
import * as analyticsManager from "../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../constants/AnalyticsConstants";

const OneClickSubscribe = (props) => {
	const [rowData, setRowData] = useState(null);
	const [gridApi, setGridApi] = useState(null);
	const [selectedRows, setSelectedRows] = useState(0);
	const [newContactsCount, setNewContactsCount] = useState(0);
	const [totalContactCount, setTotalContactCount] = useState(0);
	const [lastCsvExportDate, setLastCsvExportDate] = useState(null);
	const [isGridRendered, setGridRendered] = useState(false);
	const [isWelcome, setIsWelcome] = useState(props.location.state?.isWelcome ? true : false);
	const [isTooltipShown, setIsTooltipShown] = useState(false);
	const target = useRef(null);

	const [columnDefs, setColumnDefs] = useState([
		{ headerName: "Subscriber Email", field: "subscriber_email_address", checkboxSelection: true, headerCheckboxSelection: true },
		{
			headerName: "Join Date",
			field: "subscriber_join_date",
			valueFormatter: dateFormatter,
			filter: "agDateColumnFilter",
			floatingFilterComponent: DateFloatingComponent,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			filterParams: {
				browserDatePicker: true,
				inRangeInclusive: true,
				comparator: (filterLocalDateAtMidnight, cellValue) => {
					var cellValueDate = moment(cellValue).format('DD/MM/YYYY');
					var cellValueParts = cellValueDate.split("/");
					var cellDate = new Date(Number(cellValueParts[2]), Number(cellValueParts[1]) - 1, Number(cellValueParts[0]));
	
					var localValueDate = moment(filterLocalDateAtMidnight).format('DD/MM/YYYY');
					var localDateParts = localValueDate.split("/");
					var localDate = new Date(Number(localDateParts[2]), Number(localDateParts[1]) - 1, Number(localDateParts[0]));
	
					if (localDate.getTime() == cellDate.getTime()) {
					return 0
					}
	
					if (cellDate < localDate) {
					return -1;
					}
	
					if (cellDate > localDate) {
					return 1;
					}
				},
			},
			sort: "desc",
		},
		{ headerName: "Campaign", 
		  field: "campaign_type",
		  filter: false,
		  valueGetter: (params) => {

			if (params.data.campaign_type == "free") {
				return "Free Partnership"
			} else if (params.data.campaign_type == "affiliate") {
				return "Affiliate"
			} else if (params.data.campaign_type == "cross_promo") {
				return "Cross-promo"
			}

			return params.data.campaign_type

		  }
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			suppressMovable: true,
			resizable: true,
			flex: 1,
			minWidth: 150,
			filter: true,
			floatingFilter: true,
			suppressMenu: true
		}),
		[]
	);

	const columnTypes = {
		nonEditableColumn: { editable: false },
		minWidth: 150,
		filter: true,
		floatingFilter: true,
		dateColumn: {
			filter: "agDateColumnFilter",
			suppressMenu: true,
		},
	};

	const onCsvExport = () => {
		gridApi.exportDataAsCsv(getParams());
		networkManager.logCsvLastImport(props.selectedNewsletterId);
		if (selectedRows > 0) {
			analyticsManager.recordEvent(kAnalyticsConstants.Subscribers.selectionExported, {"contact_count" : rowData.length, "selection_count": selectedRows, "newsletter_id" : props.selectedNewsletterId});
		} else {
			analyticsManager.recordEvent(kAnalyticsConstants.Subscribers.exported, {"contact_count" : rowData.length, "newsletter_id" : props.selectedNewsletterId});
		}
	};

	const onSelectionChanged = (event) => {
		if (event.api.getSelectedRows().length == rowData.length) {
			setSelectedRows(0);
		} else {
			setSelectedRows(event.api.getSelectedRows().length);
		}
	};

	const filterNewSubscribers = (from, to) => {

		var dateFrom = lastCsvExportDate;
		var dateTo = new Date();

		if (from && to) {
			dateFrom = moment(from, 'MM-DD-YYYY').toDate();
			dateTo = moment(to, 'MM-DD-YYYY').toDate();
		}

		const instance = gridApi.getFilterInstance('subscriber_join_date');
		instance.setModelIntoUi({
			type: "inRange",
			dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
			dateTo: moment(dateTo).format("YYYY-MM-DD"),
		});
		instance.onUiChanged(true);
	};

	const exportNewSubscribers = () => {
		filterNewSubscribers();
		gridApi.exportDataAsCsv(getParams());
		networkManager.logCsvLastImport(props.selectedNewsletterId);
		analyticsManager.recordEvent(kAnalyticsConstants.Subscribers.newSubscriberExported, {"new_subscriber_count" : newContactsCount, "newsletter_id" : props.selectedNewsletterId, "contact_count" : rowData.length});
	};

	useEffect(()=>{
		if (props.newsletterSwitched && gridApi && props.isGrowActive) {
			gridApi.showLoadingOverlay();
			setLastCsvExportDate(null);
			setRowData(null);
			fetchSubscriberList(gridApi);
		}
	},[props.newsletterSwitched]);

	useEffect(()=>{
		if (isWelcome && props.publisherProfile && props.isGrowActive) {
			const { state } = props.location;
			const stateCopy = { ...state };
			delete stateCopy.isWelcome;
			props.history.replace({ state: stateCopy });
		}
	},[isWelcome, props.publisherProfile]);

	function dateFormatter(params) {
		var date = moment(params.data.subscriber_join_date);
		var formattedDate = date.format('LLL');
		return formattedDate;
	}

	function onGridReady(params) {
		setGridApi(params.api);
		fetchSubscriberList(params.api);
	}

	function onFirstDataRendered(params) {
		setGridRendered(true);
		let from = new URLSearchParams(window.location.search).get("from");
		let to = new URLSearchParams(window.location.search).get("to");
		if (from && to) {
			filterNewSubscribers(from, to);
			return
		}
		let lastExport = new URLSearchParams(window.location.search).get("last_export");
		if (lastExport) {
			filterNewSubscribers();
			return
		}
	}

	async function fetchSubscriberList(grid) {

		const [allSubscribers, lastResponse] = await fetchSubscribersFromBackend();
		if (allSubscribers && lastResponse) {
			setLastCsvExportDate(lastResponse.last_csv_export);
			processNotificationBanner(lastResponse);
			setRowData(allSubscribers);
			setTotalContactCount(allSubscribers.length);
			if (allSubscribers.length > 0) {
				grid.hideOverlay();
			} else { 
				grid.showNoRowsOverlay();
			}
		} else {
			grid.showNoRowsOverlay();
		}

	}

	async function fetchSubscribersFromBackend() {
		
		let pageNumber = 1;
		let hasMoreData = true;
		let allSubscribers = [];
		let lastResponse = null;

		do {

			try {

				const response = await networkManager.getSubscriberList(
					props.selectedNewsletterId,
					pageNumber
				);
	
				const currentSubscribers = response.results.subscriber_list;
				allSubscribers = [...currentSubscribers, ...allSubscribers];
	
				hasMoreData = response["next"] ? true : false
				lastResponse = response.results;
				pageNumber += 1
	
			} catch (error) {
				hasMoreData = false;
			}

		} while (hasMoreData);

		return [allSubscribers, lastResponse];

	}


	function getParams() {
		let today = new Date();
		let dd = String(today.getDate()).padStart(2, "0");
		let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		let yyyy = today.getFullYear();

		return {
			onlySelected: selectedRows > 0,
			fileName: "Meco_subscriber_export_" + dd + "_" + mm + "_" + yyyy,
		};
	}

	function processNotificationBanner(response) {

		let lastCsvExport = response["last_csv_export"];

		if (!lastCsvExport) {
			setNewContactsCount(0);
			return;
		}

		let subscriberList = response["subscriber_list"];

		var subscriberAfterLastExport = [];

		var lastCsvExportDate = moment(lastCsvExport).valueOf()

		for (let subscriber of subscriberList) {
			var subscriberJoinDate = moment(subscriber.subscriber_join_date).valueOf();
			if (lastCsvExportDate < subscriberJoinDate) {
				subscriberAfterLastExport.push(subscriber);
			}
		}

		setNewContactsCount(subscriberAfterLastExport.length);

	}
	
	function processTotalContactCount() {
		return "Total subscribers: " + totalContactCount;
	}

	return (
		<div>
			{ props.loadingProfile ? <LoadingView isLoading={props.loadingProfile}/> :
				<div>
					{ newContactsCount > 0 && <div className={styles.notification_banner}>
					<div className="row align-items-center"><div className={`col-md-7 col-xs-12 ${styles.text_align_left}`}>You have <div className={styles.green_font} onClick={() => filterNewSubscribers()}>{ newContactsCount } new {newContactsCount == 1 ? "subscriber" : "subscribers"}</div> since your last export <span ref={target} onMouseEnter={() => setIsTooltipShown(!isTooltipShown)} onMouseLeave={() => setIsTooltipShown(!isTooltipShown)} style={{ cursor: "pointer" }} className={styles.info_button}> &#9432; </span> </div><div className={`col-md-5 col-xs-12 ${styles.text_align_right}`}><div className={styles.download_now} onClick={() => exportNewSubscribers()}>Export new subscribers</div></div>
					<Overlay target={target.current} show={isTooltipShown} placement="top">
						{(props) => (
							<Tooltip className="export_tooltip" {...props} wrapperStyle={{backgroundColor: "white", color: "black"}}>
								Last Export on <br/> {moment(lastCsvExportDate).format("LLL")}
							</Tooltip>
						)}
					</Overlay>
					</div> </div>}
					<div className={`${styles.button_container}`}>
						<div className="row align-items-center">
							<div className={`col-7 ${styles.text_align_left}`}>
								{ isGridRendered && <div className={styles.total_contact_count}> {processTotalContactCount ()} </div> }
							</div>
							<div className={`col-5 ${styles.text_align_right}`}>
								<div className={styles.export_csv_button} onClick={() => onCsvExport()}>
									Export CSV{selectedRows > 0 && " (" + selectedRows + ")"}
								</div>
							</div>
						</div>
					</div>
					<div className="ag-theme-alpine-dark">
						<AgGridReact rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} columnTypes={columnTypes} rowSelection="multiple" onGridReady={onGridReady} suppressRowClickSelection={true} onFirstDataRendered={onFirstDataRendered} onSelectionChanged={onSelectionChanged} domLayout="autoHeight" enableCellTextSelection={true}/>
					</div>
				</div>
			}
		</div>
	);
};

export default withRouter(OneClickSubscribe);
