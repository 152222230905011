import axios from "axios";
import * as networkConfig from "./NetworkConfig";
import moment from "moment-timezone";
import { kErrorConstants } from "../constants/ErrorConstants";
import { NLProfile, PUBReferralDetail, PUBBReferralOverviewStatsResponse, PUBAffiliateDetail, PUBBAffiliateOverviewStatsResponse } from "../models/Models";

function headers() {
	let header = { "Content-Type": "application/json" };

	const userAuthToken = networkConfig.getUserAuthToken();
	if (userAuthToken) {
		header["Authorization"] = "Token " + userAuthToken;
	}

	return header;
}

const api = axios.create({
	baseURL: networkConfig.baseURL(),
});

api.interceptors.request.use((request) => {
	console.log("Starting Request", JSON.stringify(request, null, 2));
	return request;
});

api.interceptors.response.use((response) => {
	console.log("Response:", JSON.stringify(response, null, 2));
	return response;
});

export function getSubscriberList(selectedNewsletterId, page) {
	return new Promise(function (resolve, reject) {
		api
			.get(networkConfig.Endpoints.subscriberList + "/" + selectedNewsletterId + "?page=" + page, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function logCsvLastImport(selectedNewsletterId) {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.csvLastImport + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getProfile(): Promise<NLProfile> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.profile, { headers: headers() })
			.then((response) => {
				try {
					var userProfile = new NLProfile(response["data"]);
					resolve(userProfile);
				} catch (error) {
					reject(error);
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postAlerts(payload) {
	let utcDeliveryTime = moment("07:00", "HH:mm").utc();
	let convertedDeliveryTime = moment(utcDeliveryTime).format("HH:mm");
	var deliveryTime = 7;
	if (convertedDeliveryTime) {
		deliveryTime = parseInt(convertedDeliveryTime.slice(0, 2));
	}
	payload["local_delivery_time"] = deliveryTime;

	return new Promise(function (resolve, reject) {
		api
			.post(networkConfig.Endpoints.alerts, payload, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getIntegrations(selectedNewsletterId) {
	return new Promise(function (resolve, reject) {
		api
			.get(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postIntegration(selectedNewsletterId, payload) {
	return new Promise(function (resolve, reject) {
		api
			.post(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, payload, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function deleteIntegration(selectedNewsletterId) {
	return new Promise(function (resolve, reject) {
		api
			.delete(networkConfig.Endpoints.integrations + "/" + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				resolve(response["data"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function signUpUser(signUpUserModel: Record<string, string>) {
	return new Promise(function (resolve, reject) {
		api
			.post(networkConfig.Endpoints.signUpUser, signUpUserModel, {})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function logInUser(emailAddress) {
	return new Promise((resolve, reject) => {
		let data = { email: emailAddress };

		api
			.post(networkConfig.Endpoints.logInUser, data, { headers: headers() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				var internalError = error;
				if (error.response?.status == 404) {
					internalError = kErrorConstants.logIn.nonExistantAccount;
				}
				reject(internalError);
			});
	});
}

export function validateMagicLinkToken(magicLinktoken): Promise<string> {
	return new Promise((resolve, reject) => {
		let data = { magic_link_token: magicLinktoken };

		api
			.post(networkConfig.Endpoints.validateMagicLink, data, { headers: headers() })
			.then((response) => {
				const userToken = response && response["data"]["auth_token"];
				resolve(userToken);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getReferralLink(selectedNewsletterId: string): Promise<PUBReferralDetail> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getReferralLink + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const referralDetail = new PUBReferralDetail(data);
				resolve(referralDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function createReferralLink(selectedNewsletterId: string): Promise<PUBReferralDetail> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.createReferralLink + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const referralDetail = new PUBReferralDetail(data);
				resolve(referralDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getReferralOverviewStats(selectedNewsletterId: string, selectedDate: Record<string, any>): Promise<PUBBReferralOverviewStatsResponse> {
	return new Promise((resolve, reject) => {
		var dateQuery = "";

		if (selectedDate.month && selectedDate.year) {
			const correctMonthFormat = selectedDate.month < 10 ? `0${selectedDate.month}` : selectedDate.month;
			dateQuery = "?date=" + selectedDate.year + "-" + correctMonthFormat;
		}

		var endpointUrl = networkConfig.Endpoints.getReferralOverviewStats + selectedNewsletterId + dateQuery;

		api
			.get(endpointUrl, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				var overviewStatsResponse = new PUBBReferralOverviewStatsResponse(data);
				resolve(overviewStatsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getReferralPayouts(selectedNewsletterId: string): Promise<[PUBBReferralOverviewStatsResponse]> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getReferralHistory + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"] && response["data"]["payout_history"];
				var overviewStatsResponseArray = data.map((overviewStatResponse: PUBBReferralOverviewStatsResponse) => new PUBBReferralOverviewStatsResponse(overviewStatResponse));
				resolve(overviewStatsResponseArray);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getPaypalEmail(selectedNewsletterId: string): Promise<string> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.postPaypalEmail + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				resolve(response["data"]["email_address"]);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function postPaypalEmail(selectedNewsletterId: string, email: string): Promise<void> {
	return new Promise((resolve, reject) => {
		let data = { email_address: email };

		api
			.post(networkConfig.Endpoints.postPaypalEmail + selectedNewsletterId, data, { headers: headers() })
			.then((_response) => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getAffiliateDetail(selectedNewsletterId: string): Promise<PUBAffiliateDetail> {
	return new Promise((resolve, reject) => {
		api
			.get(networkConfig.Endpoints.getAffiliateDetail + selectedNewsletterId, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				const affiliateDetail = new PUBAffiliateDetail(data);
				resolve(affiliateDetail);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function getAffiliateOverviewStats(selectedNewsletterId: string, fromDate: Record<string, any>, toDate: Record<string, any>): Promise<PUBBAffiliateOverviewStatsResponse> {
	return new Promise((resolve, reject) => {
		var dateQuery = "";

		const fromDateString = moment(fromDate).format('YYYY-MM-DD');
		const toDateString = moment(toDate).format('YYYY-MM-DD');
		dateQuery = "?start_date=" + fromDateString + "&end_date=" + toDateString;

		var endpointUrl = networkConfig.Endpoints.getAffiliateOverviewStats + selectedNewsletterId + dateQuery;

		api
			.get(endpointUrl, { headers: headers() })
			.then((response) => {
				const data = response && response["data"];
				var overviewStatsResponse = new PUBBAffiliateOverviewStatsResponse(data);
				resolve(overviewStatsResponse);
			})
			.catch((error) => {
				reject(error);
			});
	});
}