import { Route, Redirect } from "react-router-dom";
import { kLocalStorageKeys } from "../constants/kLocalStorageKeys";
import Layout from './Layout/Layout';

function ProtectedRoute({ component: Component, noSideBar, ...rest }) {

    return (
        <Route
        {...rest}
        render={(props) => {
            if (localStorage.getItem(kLocalStorageKeys.User.token) !== null) {
                return <Layout><Component /></Layout>;
            } else {
                return (<Redirect to={{ pathname: "/login"}} />);
            }
        }}

        />
    );
}

export default ProtectedRoute;
