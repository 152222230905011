import { Route, Redirect, useHistory } from "react-router-dom";
import { kLocalStorageKeys } from "../../constants/kLocalStorageKeys";
import Layout from '../Layout/Layout';
import EarnLayout from "./EarnLayout";

function EarnProtectedRoute({ component: Component, ...rest }) {
	const history = useHistory();

    const isPaymentSettings = () => {
		const queryParams = new URLSearchParams(history.location.search);
    	const isPaymentSettings = queryParams.get('paymentSettings') === 'true';
		return isPaymentSettings
	}

    return (
        <Route
        {...rest}
        render={(props) => {
            if (localStorage.getItem(kLocalStorageKeys.User.token) !== null) {
               
                if (props.location.pathname === "/earn") {
                    return (<Redirect to={{ pathname: "/earn/dashboard"}} />);
                }
                
                return <Layout><EarnLayout paymentSettings={isPaymentSettings()}><Component /></EarnLayout></Layout>;

            } else {
                return (<Redirect to={{ pathname: "/login"}} />);
            }
        }}

        />
    );
}

export default EarnProtectedRoute;
