import { Toast, ToastContainer } from 'react-bootstrap';

function ToastView(props) {

    return (
        <div>
            <ToastContainer className="p-3" position="top-center">
                <Toast key="toast" show={props.isDisplayToast} animation={true} bg={"success"} autohide={true} delay={1000}>
                    <Toast.Body className={props.isWarning ? "toast-warning" : null}>{props.message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )

}

export default ToastView;