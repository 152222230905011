import { Route, Redirect } from "react-router-dom";
import { kLocalStorageKeys } from "../../../constants/kLocalStorageKeys";
import Layout from '../../Layout/Layout';
import GrowAffiliateLayout from "./GrowAffiliateLayout";

function GrowAffiliateProtectedRoute({ component: Component, ...rest }) {

    return (
        <Route
        {...rest}
        render={(props) => {
            if (localStorage.getItem(kLocalStorageKeys.User.token) !== null) {
               
                if (props.location.pathname === "/grow/affiliate") {
                    return (<Redirect to={{ pathname: "/grow/affiliate/dashboard"}} />);
                }
                
                return <Layout><GrowAffiliateLayout><Component /></GrowAffiliateLayout></Layout>;

            } else {
                return (<Redirect to={{ pathname: "/login"}} />);
            }
        }}

        />
    );
}

export default GrowAffiliateProtectedRoute;
